import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { FaEdit, FaPlus, FaTrash, FaTimes, FaCheck } from 'react-icons/fa';

import {
  useGetPrivilegeQuery,
  useUpdatePrivilegeMutation,
} from '../../slices/privilegeApiSlice';

const PrivilegeEditScreen = () => {
  const { pageNumber } = useParams();

  const { id: privilegeId } = useParams();
  const { userInfo } = useSelector((state) => state.auth);

  const [specialityName, setSpecialityName] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const {
    data: speciality,
    refetch,
    isLoading,
    error,
  } = useGetPrivilegeQuery(privilegeId);

  const checkboxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90px',
    height: '30px',
    border: '2px solid #ccc',
    borderRadius: '4px',
    cursor: 'pointer',
  };

  const approvedStyle = {
    border: '2px solid green',
  };

  const notApprovedStyle = {
    border: '2px solid red',
  };

  const disabledStyle = {
    backgroundColor: '#e9ecef',
    cursor: 'not-allowed',
    opacity: 0.5,
  };

  const dropdownStyle = {
    backgroundColor: '#F5F5F5',
  };

  const textStyle = {
    backgroundColor: '#f5f5dc',
  };

  const [updateSpeciality, { isLoading: loadingUpdate }] =
    useUpdatePrivilegeMutation();

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    //console.log("worklistId", worklistId)
    //console.log("worklistName", worklistName)
    e.preventDefault();

    const details = {
      privilegeId,
      specialityName,
      description,
      isActive,
    };
    //console.log('in submitHandler : privilege details', details);

    try {
      await updateSpeciality({
        privilegeId,
        details,
      }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      toast.success('Privilege updated');
      refetch();
      navigate('/admin/privileges');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  /*
    useEffect(() => {
        // Set an initial date when the component mounts
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        setReviewDate(formattedDate);
        

    }, []); // The empty dependency array ensures this effect runs only once when the component mounts

*/

  useEffect(() => {
    if (speciality) {
      setSpecialityName(speciality.specialityName);
      setDescription(speciality.description);
    }
  }, [speciality]);

  return (
    <>
      <Link to='/admin/privileges' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Speciality</h1>
        {loadingUpdate && <Loader />}

        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error.data.message}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <div
              style={{
                border: '1px solid #ddd',
                padding: '10px',
                borderRadius: '10px',
                backgroundColor: '#F0F8FF',
              }}
            >
              <Form.Group controlId='specialityname'>
                <Form.Label>Speciality Name</Form.Label>

                <Form.Control
                  type='text'
                  placeholder='Speciality Name'
                  value={specialityName}
                  onChange={(e) => setSpecialityName(e.target.value)}
                  style={textStyle}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='description'>
                <Form.Label>Speciality description</Form.Label>

                <Form.Control
                  type='text'
                  placeholder='Speciality description'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  style={textStyle}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='isActive'>
                <Form.Label>Is Active?</Form.Label>
                <div
                  style={{
                    ...checkboxStyle,
                    ...(isActive ? approvedStyle : notApprovedStyle),
                    ...(isDisabled ? disabledStyle : {}),
                  }}
                  onClick={() => !isDisabled && setIsActive(!isActive)}
                >
                  {isActive ? (
                    <FaCheck style={{ color: 'green' }} />
                  ) : (
                    <FaTimes style={{ color: 'red' }} />
                  )}
                </div>
              </Form.Group>
            </div>

            <Button
              type='submit'
              variant='primary'
              style={{ marginTop: '1rem' }}
            >
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default PrivilegeEditScreen;
