//import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';

function ModalScreen() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant='outline-info' size='sm' onClick={handleShow}>
        Note
      </Button>

      <Modal show={show} onHide={handleClose} size='lg'>
        {' '}
        {/* Add size="lg" prop here */}
        <Modal.Header closeButton>
          <Modal.Title>Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            When scheduling an activity to occur every second week, or every
            third week, etc., if you have already entered an activity for that
            time slot, choose the option 'For Noting, Biweekly/Triweekly task'.
            This will ensure that the time is not duplicated in calculations.
          </div>

          <div>
            Example: Beaumont Hospital, ECG from 10:00 to 12:00 on Tuesdays
            (each week), but periodically the activity will change to Theatre
            from 10:00 to 12:00 on Tuesdays, use the option 'For Noting,
            Biweekly/Triweekly task'. Please enter details in the comment
            section.
          </div>

          <Table
            striped
            bordered
            hover
            responsive
            className='table-sm table-responsive-sm'
          >
            <thead>
              <tr>
                <th>ClinicalSite</th>
                <th>Activity</th>
                <th>Day</th>
                <th>Period</th>
                <th>Start Time</th>
                <th>End Time</th>
              </tr>
            </thead>
            <tbody>
              <tr key='1000'>
                <td>Beaumont Hospital</td>
                <td>ECG</td>
                <td>Tuesday</td>
                <td>Each Week</td>
                <td>10:00</td>
                <td>11:00</td>
              </tr>
              <tr key='2000'>
                <td>Beaumont Hospital</td>
                <td>Theatre</td>
                <td>Tuesday</td>
                <td>
                  For Noting, Biweekly/Triweekly task (see comments for details)
                </td>
                <td>10:00</td>
                <td>11:00</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalScreen;
