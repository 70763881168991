import { DIRECTORATES_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const directorateApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDirectorates: builder.query({
            query: ({ keyword, pageNumber }) => ({
                url: DIRECTORATES_URL,
                params: { keyword, pageNumber },
            }),
            keepUnusedDataFor: 5,
            providesTags: ['TheDirectorates'],
        }),

        getDirectoratesNP: builder.query({
            query: () => ({
                url: `${DIRECTORATES_URL}/list`,

            }),
            keepUnusedDataFor: 5,
            providesTags: ['TheDirectorates'],
        }),



        getDirectorateDetails: builder.query({
            query: (directorateId) => ({
                url: `${DIRECTORATES_URL}/${directorateId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        createDirectorate: builder.mutation({
            query: () => ({
                url: `${DIRECTORATES_URL}`,
                method: 'POST',
            }),
            invalidatesTags: ['TheDirectorates'],
        }),
        updateDirectorate: builder.mutation({
            query: (data) => ({
                url: `${DIRECTORATES_URL}/${data.directorateId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['TheDirectorates'],
        }),
        deleteDirectorate: builder.mutation({
            query: (directorateId) => ({
                url: `${DIRECTORATES_URL}/${directorateId}`,
                method: 'DELETE',
            }),
            providesTags: ['TheDirectorates'],
        }),


    }),
});

export const {
    useGetDirectoratesQuery,
    useGetDirectoratesNPQuery,
    useGetDirectorateDetailsQuery,
    useCreateDirectorateMutation,
    useUpdateDirectorateMutation,
    useDeleteDirectorateMutation,
} = directorateApiSlice;
