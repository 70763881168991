import React, { useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { FaTrash, FaPlus, FaEdit, FaCheck, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Message from '../../components/Message';
import Loader from '../../components/Loader';
import {
  useDeleteUserMutation,
  useGetUsersQuery,
} from '../../slices/usersApiSlice';
import {
  useGetMyWorklistsQuery,
  useCreateWorklistMutation,
  useGetWorklistDetailsQuery,
  useCreateActivityMutation,
  useUsersWithUnapprovedItemsManagerQuery,
} from '../../slices/worklistsApiSlice.js';
import '../../utils/tableStack.css';

import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridColumnMenuHideItem,
  GridToolbar,
  gridClasses,
} from '@mui/x-data-grid';
import {
  grey,
  yellow,
  common,
  orange,
  lightGreen,
  red,
} from '@mui/material/colors';
import { darken, lighten, styled } from '@mui/material/styles';
import { width } from '@mui/system';
//import { hidden } from 'colors/index.js';

//#f5f5dc
const dropdownStyle = {
  backgroundColor: '#F5F5F5', //'#F0E68C'                 //'lightyellow',
  // Add other styles as needed
};
const textStyle = {
  backgroundColor: '#f5f5dc',
  // Add other styles as needed
};

//__________________________________________________________

const UserListScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const {
    data: users,
    refetch,
    isLoading,
    error,
  } = useUsersWithUnapprovedItemsManagerQuery(userInfo._id);

  const [deleteUser] = useDeleteUserMutation();

  const deleteHandler = async (id) => {
    if (window.confirm('Are you sure')) {
      try {
        await deleteUser(id);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  //___________for the grid

  useEffect(() => {
    if (users) {
      refetch();
      //console.log('users', users);
      //console.log('users 1', users[0].user);

      const formattedRows = users
        .slice()
        .sort((a, b) => {
          // Compare by day
          const specialityComparison = a.user.speciality.localeCompare(
            b.user.speciality
          );

          if (specialityComparison !== 0) {
            return specialityComparison;
          }
          /*
        // Compare by period
        const periodComparison = a.period.localeCompare(b.period);

        if (periodComparison !== 0) {
          return periodComparison;
        }
          

        // Create Date objects for start times
        const startTimeA = new Date(0, 0, 0, a.startHour, a.startMin);
        const startTimeB = new Date(0, 0, 0, b.startHour, b.startMin);

        // Compare by start time
        const startTimeComparison = startTimeA - startTimeB;

        if (startTimeComparison !== 0) {
          return startTimeComparison;
        }

        // If start times are the same, compare by activity
        return a.activity.localeCompare(b.activity);
        */
        })
        .map((user) => ({
          id: user.user._id.toString(), // Ensure each item has a unique ID and convert ObjectId to string
          worklistId: user.worklistId,
          name: user.user.name, // Access the nested user object
          speciality: user.user.speciality,
          email: user.user.email,
          isAdmin: user.user.isAdmin,
          isManager: user.user.isManager,
          isApproved: user.user.isApproved,
        }));

      setLoading(false);

      setRows(formattedRows);
      //get window size
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [users]);

  const navigate = useNavigate();

  const handleEdit = (id, userid) => {
    //console.log(`Edit item with ID: ${id}`);
    //console.log(`Edit item with ID: ${uKey}`);

    navigate(`/manager/worklist/${id}/edit/${userid}`);
  };

  const handleDelete = (id) => {
    //console.log(`Delete item with ID: ${id}`);
    // Add your delete handling logic here
  };

  const columns = [
    //{ field: 'id', headerName: 'ID', hide: true, width: 90 },
    { field: 'speciality', headerName: 'Speciality', width: 250 },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
    },

    { field: 'email', headerName: 'Email', width: 200 },

    {
      field: 'isAdmin',
      headerName: 'Admin',
      width: 100,
      renderCell: (params) =>
        params.value ? (
          <FaCheck style={{ color: 'green' }} />
        ) : (
          <FaTimes style={{ color: 'red' }} />
        ),
    },
    {
      field: 'isManager',
      headerName: 'manager',
      width: 100,
      renderCell: (params) =>
        params.value ? (
          <FaCheck style={{ color: 'green' }} />
        ) : (
          <FaTimes style={{ color: 'red' }} />
        ),
    },
    {
      field: 'isApproved',
      headerName: 'Can log in',
      width: 100,
      renderCell: (params) =>
        params.value ? (
          <FaCheck style={{ color: 'green' }} />
        ) : (
          <FaTimes style={{ color: 'red' }} />
        ),
    },

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            size='small'
            variant='outlined'
            color='info'
            //onClick={() => handleEdit(params.id, params.row.UKey))}
            onClick={() => handleEdit(params.row.worklistId, params.row.id)}
          >
            <FaEdit /> Edit
          </Button>
        </Box>
      ),
    },
  ];

  const [responsive, setResponsive] = useState('vertical');
  const [tableBodyHeight, setTableBodyHeight] = useState('400px');
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState('');
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      //console.log(action);
      console.dir(state);
    },
  };

  //valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,

  /* // Otherwise filter will be applied on fields such as the hidden column id
const columns = React.useMemo(
  () => data.columns.filter((column) => VISIBLE_FIELDS.includes(column.field)),
  [data.columns],
); */

  //<MUIDataTable title={'ACME Employee list'} data={data} columns={columns} options={options} />

  //options={options}

  //
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 1,
      bottom: params.isLastVisible ? 0 : 1,
    };
  }, []);
  //
  //
  const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);
  //
  //

  const StyledDataGrid = styled(DataGrid)`
    & .${gridClasses.row}.lightGreenRow {
      background-color: ${lightGreen[100]};
    }
    & .${gridClasses.row}.yellowRow {
      background-color: ${yellow[100]};
    }
    & .${gridClasses.row}.redRow {
      background-color: ${red[100]};
    }

    & .${gridClasses.row}.orangeRow {
      background-color: ${orange[100]};
    }

    &
      .${gridClasses.row}:nth-child(odd):not(.yellowRow):not(.orangeRow):not(
        .lightGreenRow
      ):not(.redRow) {
      background-color: ${grey[200]};
    }

    &
      .${gridClasses.row}:nth-child(even):not(.yellowRow):not(.orangeRow):not(
        .lightGreenRow
      ):not(.redRow) {
      background-color: ${common.white}; // Assuming 'common.white' was intended
    }
  `;

  //
  // Go Back - the windowWidth is {windowWidth}

  //_______________________

  //className='table-sm'
  /*
  <th>WorklistID</th>
  <th>ID</th>
  <td>{user.worklistId}</td>
  <td>{user.user._id}</td>

  */

  return (
    <>
      <Row>
        {/*
        {windowWidth < 900 ? (
          <Col md={12}>
            <h1>Approval Required</h1>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <div style={{ overflowX: 'auto' }}>
                <Table
                  striped
                  bordered
                  hover
                  responsive
                  className='custom-table'
                >
                  <thead>
                    <tr>
                      <th>Speciality</th>
                      <th>Name</th>
                      <th>Email</th>

                      <th>Admin</th>
                      <th>Manager</th>
                      <th>Login Enabled</th>

                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {users.map((user) => (
                      <tr key={user.worklistId}>
                        <td>{user.user.speciality}</td>
                        <td>{user.user.name}</td>
                        <td>
                          <a href={`mailto:${user.user.email}`}>
                            {user.user.email}
                          </a>
                        </td>
                        <td>
                          {user.user.isAdmin ? (
                            <FaCheck style={{ color: 'green' }} />
                          ) : (
                            <FaTimes style={{ color: 'red' }} />
                          )}
                        </td>

                        <td>
                          {user.user.areyouaclinicaldirector ? (
                            <FaCheck style={{ color: 'green' }} />
                          ) : (
                            <FaTimes style={{ color: 'red' }} />
                          )}
                        </td>

                        <td>
                          {user.user.isApproved ? (
                            <FaCheck style={{ color: 'green' }} />
                          ) : (
                            <FaTimes style={{ color: 'red' }} />
                          )}
                        </td>

                        {(!user.user.isAdmin || user.user.isAdmin) && (
                          <td>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <LinkContainer
                                to={`/manager/worklist/${user.worklistId}/edit/${user.user._id}`}
                              >
                                <Button
                                  variant='outline-info'
                                  className='btn-sm mx-2'
                                >
                                  <FaEdit /> Edit Worklist
                                </Button>
                              </LinkContainer>

                              <Button
                                variant='warning'
                                className='btn-sm'
                                onClick={() => deleteHandler(user.user._id)}
                              >
                                <FaTrash
                                  className='text-end'
                                  style={{ color: 'white' }}
                                />{' '}
                                Delete
                              </Button>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </Col>
        )*/}
        {windowWidth < 900 ? (
          isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>
              {error?.data?.message || error.error}
            </Message>
          ) : (
            <div>
              <h1>Approval Required</h1>
              {users.map((user) => (
                <Card key={user.worklistId} className='mb-3'>
                  <Card.Body>
                    <Card.Text>
                      <strong>Speciality:</strong> {user.user.speciality}
                    </Card.Text>
                    <Card.Text>
                      <strong>Name:</strong> {user.user.name}
                    </Card.Text>
                    <Card.Text>
                      <strong>Email:</strong>{' '}
                      <a href={`mailto:${user.user.email}`}>
                        {user.user.email}
                      </a>
                    </Card.Text>
                    <Card.Text>
                      <strong>Admin:</strong>{' '}
                      {user.user.isAdmin ? (
                        <FaCheck style={{ color: 'green' }} />
                      ) : (
                        <FaTimes style={{ color: 'red' }} />
                      )}
                    </Card.Text>
                    <Card.Text>
                      <strong>Manager:</strong>{' '}
                      {user.user.areyouaclinicaldirector ? (
                        <FaCheck style={{ color: 'green' }} />
                      ) : (
                        <FaTimes style={{ color: 'red' }} />
                      )}
                    </Card.Text>
                    <Card.Text>
                      <strong>Login Enabled:</strong>{' '}
                      {user.user.isApproved ? (
                        <FaCheck style={{ color: 'green' }} />
                      ) : (
                        <FaTimes style={{ color: 'red' }} />
                      )}
                    </Card.Text>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <LinkContainer
                        to={`/manager/worklist/${user.worklistId}/edit/${user.user._id}`}
                      >
                        <Button variant='outline-info' className='btn-sm mx-2'>
                          <FaEdit /> Edit Worklist
                        </Button>
                      </LinkContainer>
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </div>
          )
        ) : (
          <Col md={12} className='d-flex flex-column align-items-start'>
            <h1>Approval Required</h1>

            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <Row className='w-100 mt-3'>
                <Box sx={{ height: 800, width: '100%' }}>
                  <StyledDataGrid
                    density='compact'
                    rows={rows}
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    loading={loading}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 50,
                        },
                      },
                      columns: {
                        columnVisibilityModel: {
                          // Hide columns name , the other columns will remain visible
                          user: false,
                        },
                      },
                    }}
                    pageSizeOptions={[50]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    /*getRowClassName={(params) => {
                    if (params.row.activity.startsWith('Lunch Break')) {
                      return 'lightGreenRow';
                    } else if (params.row.period.startsWith('For Noting')) {
                      return 'orangeRow';
                    } else if (params.row.activity.startsWith('zPlease')) {
                      return 'redRow';
                    }
                    return '';
                  }}
                    */
                    getRowSpacing={getRowSpacing}
                  />
                </Box>
              </Row>
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

export default UserListScreen;
