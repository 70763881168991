import { CLINICALSCOMM_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const clinicalSCommitmentsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getClinicalSCommitments: builder.query({
            query: ({ keyword, pageNumber }) => ({
                url: CLINICALSCOMM_URL,
                params: { keyword, pageNumber },
            }),
            keepUnusedDataFor: 5,
            providesTags: ['ClinicalSCommitments'],
        }),


        getMyClinicalSCommitments: builder.query({
            query: ( id ) => ({
                url: `${CLINICALSCOMM_URL}/mine/${id}`,
                params: { id },
            }),
            keepUnusedDataFor: 5,

        }),




        getClinicalSCommitmentDetails: builder.query({
            query: (clinicalSCommitmentId) => ({
                url: `${CLINICALSCOMM_URL}/${clinicalSCommitmentId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        createClinicalSCommitment: builder.mutation({
            query: () => ({
                url: `${CLINICALSCOMM_URL}`,
                method: 'POST',
            }),
            invalidatesTags: ['ClinicalSCommitment'],
        }),
        updateClinicalSCommitment: builder.mutation({
            query: (data) => ({
                url: `${CLINICALSCOMM_URL}/${data.clinicalSCommitmentId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['ClinicalSCommitment'],
        }),
        deleteClinicalSCommitment: builder.mutation({
            query: (clinicalSCommitmentId) => ({
                url: `${CLINICALSCOMM_URL}/${clinicalSCommitmentId}`,
                method: 'DELETE',
            }),
            providesTags: ['ClinicalSCommitment'],
        }),


    }),
});

export const {
    useGetClinicalSCommitmentsQuery,
    useGetMyClinicalSCommitmentsQuery,
    useGetClinicalSCommitmentDetailsQuery,
    useCreateClinicalSCommitmentMutation,
    useUpdateClinicalSCommitmentMutation,
    useDeleteClinicalSCommitmentMutation,
} = clinicalSCommitmentsApiSlice;
