import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useGetProductsQuery } from '../slices/productsApiSlice';
import { Link } from 'react-router-dom';
import Product from '../components/Product';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import ProductCarousel from '../components/ProductCarousel';
import Meta from '../components/Meta';

import { useDispatch, useSelector } from 'react-redux';

const HomeScreen = () => {
  const { pageNumber, keyword } = useParams();

  const { userInfo } = useSelector((state) => state.auth);
  let accessType = '';

  // User must be an admin

  if (userInfo.isRadAdmin) {
    accessType = 'All';
  } else {
    accessType = 'Staff';
  }

  //console.log('userInfo.isRadAdmin ', userInfo.isRadAdmin);
  //console.log('userInfo ', userInfo);

  const { data, isLoading, error } = useGetProductsQuery({
    keyword,
    accessType,
  });

  //accessType: 'All',

  return (
    <>
      {!keyword ? (
        <ProductCarousel />
      ) : (
        <Link to='/' className='btn btn-light mb-4'>
          Go Back
        </Link>
      )}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Meta />

          <h1>Select a Menu Option</h1>
          <Row className='mx-0'>
            {data.products.map((product) => (
              <Col
                key={product._id}
                sm={12}
                md={6}
                lg={4}
                xl={3}
                className='px-0'
              >
                <Product product={product} />
              </Col>
            ))}
          </Row>
          <Paginate
            pages={data.pages}
            page={data.page}
            keyword={keyword ? keyword : ''}
          />
        </>
      )}
    </>
  );
};

export default HomeScreen;
