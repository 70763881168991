import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FaEdit, FaPlus, FaTrash, FaTimes, FaCheck } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import Message from '../../components/Message.jsx';
import Loader from '../../components/Loader.jsx';

import {
  useGetPrivilegeQuery,
  useCreateItemMutation,
  useDeletePrivilegeItemMutation,
} from '../../slices/privilegeApiSlice.js';
//import { setCredentials } from '../slices/authSlice.js';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import '../../../src/components/TimePicker.css';
import MyClinicalSCommitments from '../../components/MyClinicalCommitments.jsx';
import MyProfile from '../../components/MyProfile.jsx';
import { calcTotal } from '../../utils/calcTotals.js';
import ModalScreen from '../../components/Modal.jsx';
import '../../utils/tableStack.css';

import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid';
import {
  grey,
  yellow,
  common,
  orange,
  lightGreen,
  red,
} from '@mui/material/colors';
import { darken, lighten, styled } from '@mui/material/styles';

//#f5f5dc
const dropdownStyle = {
  backgroundColor: '#F5F5F5', //'#F0E68C'                 //'lightyellow',
  // Add other styles as needed
};
const textStyle = {
  backgroundColor: '#f5f5dc',
  // Add other styles as needed
};

const SpecilityPrivilegeListScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);

  //console.log('userInfo._id', userInfo._id);

  //const { data: worklists, isLoading, error } = useGetMyWorklistsQuery();

  const { id: specilityId } = useParams();

  // get the activities from the worklist
  const {
    data: speciality,
    refetch,
    isLoading,
    error,
  } = useGetPrivilegeQuery(specilityId);

  const dispatch = useDispatch();

  const [createPrivlege, { isLoading: loadingCreate }] =
    useCreateItemMutation(specilityId);

  const createActivityHandler = async () => {
    if (
      window.confirm(
        `Are you sure you want to create a new Item? for ${specilityId}`
      )
    ) {
      try {
        await createPrivlege(specilityId);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };
  //className='my-3'
  //className='table-sm table-responsive-sm'

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //

  const [deleteWorklistActivity] = useDeletePrivilegeItemMutation();

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (speciality) {
      refetch();
      //console.log('speciality in SPlistscreen', speciality);

      if (speciality && Array.isArray(speciality.privileges)) {
        const formattedRows = speciality.privileges
          .filter((item) => item.isActive) // Filter out inactive items
          .slice()
          .sort((a, b) => {
            // Check if either privilegeName starts with 'z'
            const aStartsWithZ = a.privilegeName.toLowerCase().startsWith('z');
            const bStartsWithZ = b.privilegeName.toLowerCase().startsWith('z');

            if (aStartsWithZ && !bStartsWithZ) {
              return -1; // a should come before b
            }
            if (!aStartsWithZ && bStartsWithZ) {
              return 1; // b should come before a
            }

            // If both or neither start with 'z', compare normally
            const itemComparison = a.privilegeName.localeCompare(
              b.privilegeName
            );

            if (itemComparison !== 0) {
              return itemComparison;
            }

            // Additional comparison logic if needed
            return 0; // Ensure a consistent return value
          })

          /*

          .sort((a, b) => {
            // Compare by item name (assuming 'Item' is a string)
            const itemComparison = a.Item.localeCompare(b.Item);

            // Return the comparison result, always returning a value
            return itemComparison;
          })

          */

          .map((item) => ({
            id: item._id.toString(), // Ensure each item has a unique ID and convert ObjectId to string

            number: item.number, //: { type: Number, required: true },
            privilegeName: item.privilegeName, //: { type: String, required: true },
            privilegeId: item._id,
            description: item.description, //: { type: String, required: true },
            isActive: item.isActive, //: {type: Boolean, default: true, },
          }));

        setLoading(false);

        setRows(formattedRows);
      }
      //get window size
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [speciality]);

  const navigate = useNavigate();

  const handleEdit = (id, privilegeId) => {
    //console.log(`Edit item with ID: ${id}`);
    //console.log(`Edit item with ID: ${uKey}`);

    navigate(`/admin/privilege/${id}/item/${privilegeId}`);
  };

  const handleDelete = async (e, specialityId, privilegeId) => {
    //console.log('id', id);
    //console.log('privilegeId', privilegeId);

    e.preventDefault();

    try {
      //console.log('worklistId', worklistId);
      //console.log('uKey ', uKey);

      await deleteWorklistActivity({ specialityId, privilegeId }).unwrap();

      toast.success('Item Deleted');
      refetch();
    } catch (err) {
      toast.error(err?.message || 'Failed to delete item');
    }
  };

  const columns = [
    //{ field: 'id', headerName: 'ID', hide: true, width: 90 },
    // { field: 'user', headerName: 'User', hide: true, width: 150 },
    // {field: 'worklistName', headerName: 'Worklist Name', width: 150,hide: true },

    //{ field: 'UKey', headerName: 'UKey', width: 150, hide: true },
    //{ field: 'number', headerName: 'Number', width: 150 },
    { field: 'privilegeName', headerName: 'Privilege Name', width: 200 },
    { field: 'description', headerName: 'Description', width: 700 },
    //{ field: 'period', headerName: 'Period', width: 100 },

    {
      field: 'isActive',
      headerName: 'Active',
      width: 100,
      renderCell: (params) =>
        params.value ? (
          <FaCheck style={{ color: 'green' }} />
        ) : (
          <FaTimes style={{ color: 'red' }} />
        ),
    },

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            size='small'
            variant='outlined'
            color='info'
            //onClick={() => handleEdit(params.id, params.row.UKey))}
            onClick={() => handleEdit(speciality._id, params.row.privilegeId)}
          >
            <FaEdit /> Edit
          </Button>
          <Button
            size='small'
            variant='contained'
            color='warning'
            //onClick={() => handleDelete(params.id)}
            onClick={(e) =>
              handleDelete(e, speciality._id, params.row.privilegeId)
            }
          >
            <FaTrash style={{ color: 'brown' }} /> Delete
          </Button>
        </Box>
      ),
    },
  ];

  const [responsive, setResponsive] = useState('vertical');
  const [tableBodyHeight, setTableBodyHeight] = useState('400px');
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState('');
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      //console.log(action);
      console.dir(state);
    },
  };

  //valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,

  /* // Otherwise filter will be applied on fields such as the hidden column id
  const columns = React.useMemo(
    () => data.columns.filter((column) => VISIBLE_FIELDS.includes(column.field)),
    [data.columns],
  ); */

  //<MUIDataTable title={'ACME Employee list'} data={data} columns={columns} options={options} />

  //options={options}

  //
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 1,
      bottom: params.isLastVisible ? 0 : 1,
    };
  }, []);
  //
  //
  const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);
  //
  //

  const StyledDataGrid = styled(DataGrid)`
    & .${gridClasses.row}.lightGreenRow {
      background-color: ${lightGreen[100]};
    }
    & .${gridClasses.row}.yellowRow {
      background-color: ${yellow[100]};
    }
    & .${gridClasses.row}.redRow {
      background-color: ${red[100]};
    }

    & .${gridClasses.row}.orangeRow {
      background-color: ${orange[100]};
    }

    &
      .${gridClasses.row}:nth-child(odd):not(.yellowRow):not(.orangeRow):not(
        .lightGreenRow
      ):not(.redRow) {
      background-color: ${grey[200]};
    }

    &
      .${gridClasses.row}:nth-child(even):not(.yellowRow):not(.orangeRow):not(
        .lightGreenRow
      ):not(.redRow) {
      background-color: ${common.white}; // Assuming 'common.white' was intended
    }
  `;

  //
  // Go Back - the windowWidth is {windowWidth}

  return (
    <>
      <Link to='/admin/privileges' className='btn btn-light mb-4'>
        Go Back
      </Link>

      <Row>
        <Row>
          <Col md={4}></Col>
        </Row>

        <Row className='justify-content-between'>
          {' '}
          {/* Distributes content between left and right */}
          <Col xs='auto'>
            {' '}
            {/* Ensures button is aligned to the right   onClick={() => alert('Button Clicked!')}  */}
            <Button size='sm' variant='primary' onClick={createActivityHandler}>
              {' '}
              <FaPlus />
              New Privilege
            </Button>
          </Col>
        </Row>

        {windowWidth < 900 ? (
          isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>
              {error?.data?.message || error.error}
            </Message>
          ) : (
            <div>
              <strong>Speciality:</strong> <h2>{speciality.specialityName}</h2>
              <h3>Privileges</h3>
              {speciality.privileges
                .filter((item) => item.isActive) // Filter out inactive items
                .slice()
                .sort((a, b) => {
                  // Check if either privilegeName starts with 'z'
                  const aStartsWithZ = a.privilegeName
                    .toLowerCase()
                    .startsWith('z');
                  const bStartsWithZ = b.privilegeName
                    .toLowerCase()
                    .startsWith('z');

                  if (aStartsWithZ && !bStartsWithZ) {
                    return -1; // a should come before b
                  }
                  if (!aStartsWithZ && bStartsWithZ) {
                    return 1; // b should come before a
                  }

                  // If both or neither start with 'z', compare normally
                  const itemComparison = a.privilegeName.localeCompare(
                    b.privilegeName
                  );

                  if (itemComparison !== 0) {
                    return itemComparison;
                  }

                  // Additional comparison logic if needed
                  return 0; // Ensure a consistent return value
                })
                .map((item) => (
                  <Card
                    key={item._id}
                    className={`mb-3 ${
                      item.privilegeName.startsWith('z') ||
                      item.privilegeName.startsWith('0') ||
                      item.privilegeName.startsWith('For Noting')
                        ? 'highlightRow'
                        : ''
                    }`}
                  >
                    <Card.Body
                      style={{
                        backgroundColor: item.privilegeName.startsWith('z')
                          ? 'rgb(254, 194, 192)'
                          : 'rgb(238,239,206)',
                      }}
                    >
                      {' '}
                      {/*'rgb(239,222,205)' */}
                      <Card.Text>
                        <strong>Privilege:</strong>{' '}
                        <h2> {item.privilegeName} </h2>
                      </Card.Text>
                      <Card.Text>
                        <strong>Description:</strong> {item.description}
                      </Card.Text>
                      <Card.Text>
                        <strong>Active:</strong>{' '}
                        {item.isActive ? (
                          <FaCheck style={{ color: 'green' }} />
                        ) : (
                          <FaTimes style={{ color: 'red' }} />
                        )}
                      </Card.Text>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LinkContainer
                          //to={`/manager/worklist/${worklist._id}/activity/${item.UKey}/${userid}`}
                          to={`/admin/privilege/${speciality._id}/item/${item._id}`}
                        >
                          <Button
                            size='sm'
                            variant='outline-info'
                            className='btn-sm mx-2'
                          >
                            <FaEdit /> Edit Item
                          </Button>
                        </LinkContainer>

                        <Button
                          size='sm'
                          variant='warning'
                          className='btn-sm'
                          //onClick={handleDelete('', worklist._id, item.UKey)}
                          onClick={(e) =>
                            handleDelete(e, speciality._id, item._id)
                          }
                        >
                          <FaTrash
                            className='text-end'
                            style={{ color: 'white' }}
                          />{' '}
                          Delete
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                ))}
            </div>
          )
        ) : (
          <Col md={12} className='d-flex  justify-content-between'>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <Box sx={{ height: 800, width: '100%' }}>
                <h2>Speciality ( {speciality.specialityName} )</h2>
                <StyledDataGrid
                  density='compact'
                  rows={rows}
                  columns={columns}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  loading={loading}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 50,
                      },
                    },
                  }}
                  pageSizeOptions={[50]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  getRowClassName={(params) => {
                    if (params.row.privilegeName.startsWith('Lunch Break')) {
                      return 'lightGreenRow';
                    } else if (
                      params.row.privilegeName.startsWith('For Noting')
                    ) {
                      return 'orangeRow';
                    } else if (params.row.privilegeName.startsWith('z')) {
                      return 'redRow';
                    }
                    return '';
                  }}
                  getRowSpacing={getRowSpacing}
                />
              </Box>
            )}
          </Col>
        )}
      </Row>

      {/*//
      // */}
    </>
  );
};

export default SpecilityPrivilegeListScreen;
