import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import {
  useGetDirectorateDetailsQuery,
  useUpdateDirectorateMutation,
} from '../../slices/adminDirectorateApiSlice';

const DirectorateEditScreen = () => {
  const { id: directorateId } = useParams();

  //console.log('directorateId', directorateId);

  const [directorate, setDirectorate] = useState('');
  const [description, setDescription] = useState('');
  const [activeYorN, setActiveYorN] = useState('');

  const {
    data: directorates,
    isLoading,
    refetch,
    error,
  } = useGetDirectorateDetailsQuery(directorateId);

  //#f5f5dc
  const dropdownStyle = {
    backgroundColor: '#F5F5F5', //'lightyellow',
    // Add other styles as needed
  };
  const textStyle = {
    backgroundColor: '#f5f5dc',
    // Add other styles as needed
  };

  const [updateDirectorate, { isLoading: loadingUpdate }] =
    useUpdateDirectorateMutation();

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updateDirectorate({
        directorateId,
        directorate,
        description,
        activeYorN,
      }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      toast.success('Directorate updated');
      refetch();
      navigate('/admin/directoratelist');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (directorates) {
      setDirectorate(directorates.directorate);
      setDescription(directorates.description);
      setActiveYorN(directorates.activeYorN);
    }
  }, [directorates]);

  return (
    <>
      <Link to='/admin/directoratelist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Directorate..</h1>
        {loadingUpdate && <Loader />}
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error.data.message}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <div
              style={{
                border: '1px solid #ddd',
                padding: '10px',
                borderRadius: '10px',
                backgroundColor: '#F0F8FF',
              }}
            >
              <Form.Group controlId='name'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Name'
                  value={directorate}
                  onChange={(e) => setDirectorate(e.target.value)}
                  style={textStyle}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='description'>
                <Form.Label>Description - Comments</Form.Label>

                <Form.Control
                  type='text'
                  placeholder='Description - Comments'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  style={textStyle}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='activeYorN'>
                <Form.Label>Active Yes or No</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Active YorN'
                  value={activeYorN}
                  onChange={(e) => setActiveYorN(e.target.value)}
                  style={dropdownStyle}
                >
                  <option value={activeYorN}>{activeYorN}</option>
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
                </Form.Control>
              </Form.Group>
            </div>

            <Button
              type='submit'
              variant='primary'
              style={{ marginTop: '1rem' }}
            >
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default DirectorateEditScreen;
