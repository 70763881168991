import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import {
    useGetClinicalDirectorDetailsQuery,
    useUpdateClinicalDirectorMutation,

} from '../../slices/clinicalDirectorsApiSlice';

const ClinicalDirectorEditScreen = () => {
    const { id: clinicalDirectorId } = useParams();

    const [clinicalDirectorName, setClinicalDirectorName] = useState('');
    const [description, setDescription] = useState('');
    const [activeYorN, setActiveYorN] = useState('');
    const [directorate, setDirectorate] = useState('');




    const {
        data: clinicalDirector,
        isLoading,
        refetch,
        error,
    } = useGetClinicalDirectorDetailsQuery(clinicalDirectorId);

    //#f5f5dc
    const dropdownStyle = {
        backgroundColor: '#F5F5F5'                 //'lightyellow',
        // Add other styles as needed
    };
    const textStyle = {
        backgroundColor: '#f5f5dc'
        // Add other styles as needed
    };



    const [updateClinicalDirector, { isLoading: loadingUpdate }] =
        useUpdateClinicalDirectorMutation();


    const navigate = useNavigate();

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            await updateClinicalDirector({
                clinicalDirectorId,
                clinicalDirectorName,
                description,
                activeYorN,
                directorate,

            }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
            toast.success('Clinical Director updated');
            refetch();
            navigate('/admin/clinicalDirectorlist');
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    useEffect(() => {
        if (clinicalDirector) {
            setClinicalDirectorName(clinicalDirector.clinicalDirectorName);
            setDescription(clinicalDirector.description);
            setActiveYorN(clinicalDirector.activeYorN);
            setDirectorate(clinicalDirector.directorate);

        }
    }, [clinicalDirector]);


    return (
        <>
            <Link to='/admin/clinicalDirectorlist' className='btn btn-light my-3'>
                Go Back
            </Link>
            <FormContainer>
                <h1>Edit Clinical Director</h1>
                {loadingUpdate && <Loader />}
                {isLoading ? (
                    <Loader />
                ) : error ? (
                    <Message variant='danger'>{error.data.message}</Message>
                ) : (
                    <Form onSubmit={submitHandler}>


                        <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '10px', backgroundColor: '#F0F8FF' }}>

                            <Form.Group controlId='name'>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter Director Name'
                                    value={clinicalDirectorName}
                                    onChange={(e) => setClinicalDirectorName(e.target.value)} style={textStyle}
                                ></Form.Control>
                            </Form.Group>



                            <Form.Group controlId='directorate'>
                                <Form.Label>Directorate</Form.Label>


                                <Form.Control
                                    as='select'
                                    placeholder='Directorate'
                                    value={activeYorN}
                                    onChange={(e) => setDirectorate(e.target.value)} style={dropdownStyle} >
                                    <option value={directorate}>{directorate}</option>
                                    <option value='Critical Care and Anaesthesia Directorate'>Critical Care and Anaesthesia Directorate</option>
                                    <option value='Emergency Medicine Directorate'>Emergency Medicine Directorate</option>

                                    <option value='Laboratory Directorate'>Laboratory Directorate</option>
                                    <option value='Medical Directorate'>Medical Directorate</option>
                                    <option value='Neurocent Directorate'>Neurocent Directorate</option>
                                    <option value='Radiology Directorate'>Radiology Directorate</option>
                                    <option value='Surgical Directorate'>Surgical Directorate</option>
                                    <option value='TUN Nephrology Directorate'>TUN Nephrology Directorate </option>
                                    <option value='TUN Urology and Transplant Directorate'>TUN Urology and Transplant Directorate</option>



                                </Form.Control>


                            </Form.Group>









                            <Form.Group controlId='description'>
                                <Form.Label>Description - Comments</Form.Label>

                                <Form.Control
                                    type='text'
                                    placeholder='Description - Comments'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)} style={textStyle}
                                ></Form.Control>
                            </Form.Group>




                            <Form.Group controlId='activeYorN'>
                                <Form.Label>Active Yes or No</Form.Label>


                                <Form.Control
                                    as='select'
                                    placeholder='Active YorN'
                                    value={activeYorN}
                                    onChange={(e) => setActiveYorN(e.target.value)} style={dropdownStyle} >
                                    <option value={activeYorN}>{activeYorN}</option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>


                                </Form.Control>


                            </Form.Group>

                        </div>



                        <Button
                            type='submit'
                            variant='primary'
                            style={{ marginTop: '1rem' }}
                        >
                            Update
                        </Button>
                    </Form>
                )}
            </FormContainer>
        </>
    );
};

export default ClinicalDirectorEditScreen;
