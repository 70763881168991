import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ModalTimelap = ({ show, handleClose, handleConfirm, worklistId }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Time Overlap Detected</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Overlap detected in worklist {worklistId}. Please review the timings.
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Edit the record
        </Button>
        <Button variant='primary' onClick={handleConfirm}>
          Keep the overlaped record
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTimelap;
