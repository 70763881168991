import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import Message from './Message';
import Loader from './Loader';
import Paginate from './PaginateCommitments';
import {
  useGetMyClinicalSCommitmentsQuery,
  useDeleteClinicalSCommitmentMutation,
  useCreateClinicalSCommitmentMutation,
} from '../slices/clinicalSCommitmentsApiSlice';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import {
  useGetWorklistDayTotalsQuery,
  useGetWorklistCSTotalsQuery,
} from '../slices/worklistsApiSlice';

const MyClinicalSCommitments = ({ userid, worklistId }) => {
  const { pageNumber } = useParams();
  const { userInfo } = useSelector((state) => state.auth);

  let theUser = '';

  if (userid) {
    // If userid has a value
    theUser = userid;
  } else {
    // If userid is null
    theUser = userInfo._Id;
  }

  //pageNumber,
  const { data, isLoading, error, refetch } =
    useGetMyClinicalSCommitmentsQuery(theUser);

  const [deleteClinicalSCommitment, { isLoading: loadingDelete }] =
    useDeleteClinicalSCommitmentMutation();

  const deleteHandler = async (id) => {
    if (window.confirm(`Are you sure  ${id}`)) {
      try {
        await deleteClinicalSCommitment(id);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const [createClinicalSCommitment, { isLoading: loadingCreate }] =
    useCreateClinicalSCommitmentMutation();

  const createClinicalSCommitmentHandler = async () => {
    if (
      window.confirm(
        'Are you sure you want to create a new Clinical Commitment?'
      )
    ) {
      try {
        await createClinicalSCommitment();
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  // Create a map from the first dataset for quick lookup
  const {
    data: worklistsCSTotal,
    isLoading: isLoadingCS,
    error: errorCS,
    refetch: refetchCS,
  } = useGetWorklistCSTotalsQuery(worklistId);

  // Handle loading and error states
  if (isLoadingCS || isLoading) {
    return <div>Loading...</div>;
  }

  if (errorCS || error) {
    <div>
      Error loading data: {errorCS ? errorCS.message : ''}{' '}
      {error ? error.message : ''}
    </div>;
  }

  // Create a map from the first dataset for quick lookup
  const worklistsMap = (worklistsCSTotal || []).reduce((map, item) => {
    map[item.clinicalSite] = item.totalTime;
    return map;
  }, {});

  // Merge the datasets
  const mergedData = data.clinicalSCommitments.map((clinicalSCommitment) => {
    return {
      ...clinicalSCommitment,
      totalTime: worklistsMap[clinicalSCommitment.clinicalSiteName] || 'N/A', // Default to 'N/A' if no match found
    };
  });

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <LinkContainer to='/clinicalSCommitmentlist'>
            <div className='btn btn-primary btn-sm'>
              {' '}
              {/* Using Bootstrap button classes */}
              Clinical Commitments
            </div>
          </LinkContainer>
        </Col>
      </Row>

      {loadingCreate && <Loader />}
      {loadingDelete && <Loader />}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error.data.message}</Message>
      ) : (
        <>
          <Table
            striped
            bordered
            hover
            responsive
            className='table-sm'
            style={{ fontSize: '0.8rem', padding: '0.1rem' }}
          >
            <thead>
              <tr>
                <th style={{ width: '10%' }}>Clinical Site Name</th>
                <th style={{ width: '10%' }}>Name</th>
                <th style={{ backgroundColor: 'lightyellow', width: '10%' }}>
                  Post Hours
                </th>
                <th style={{ width: '10%' }}>
                  Hours Accounted for in Worklists
                </th>
              </tr>
            </thead>

            <tbody>
              {mergedData.map((item) => (
                <tr key={item._id}>
                  <td style={{ width: '10%' }}>{item.clinicalSiteName}</td>
                  <td style={{ width: '10%' }}>{item.consultantName}</td>
                  <td style={{ backgroundColor: 'lightyellow', width: '10%' }}>
                    {item.postHours}
                  </td>
                  <td>{item.totalTime}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};
//<Paginate pages={data.pages} page={data.page} isAdmin={true} />
export default MyClinicalSCommitments;
