import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit, FaPlus, FaTrash, FaTimes, FaCheck } from 'react-icons/fa';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import TimePicker from '../../components/TimePicker.jsx';
import {
  useGetMyWorklistsQuery,
  useCreateWorklistMutation,
  useGetWorklistDetailsQuery,
  useGetWorklistItemsQuery,
  useUpdateWorklistMutation,
  useUpdateWorklistActivityMutation,
} from '../../slices/worklistsApiSlice';
import { useGetMyClinicalSCommitmentsQuery } from '../../slices/clinicalSCommitmentsApiSlice';
//import { setCredentials } from '../../slices/authSlice';
import FormContainer from '../../../src/components/FormContainer.jsx';

//../../src/components/FormContainer
import {
  useGetActivitiesNoPageQuery,
  useDeleteActivityMutation,
  useCreateActivityMutation,
} from '../../slices/activitiesApiSlice';
import { useGetClinicalSitesNPQuery } from '../../slices/clinicalSitesApiSlice';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../src/components/TimePicker.css';
import ModalScreen from '../../components/ModalActivityNote.jsx';

const checkboxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '90px',
  height: '30px',
  border: '2px solid #ccc',
  borderRadius: '4px',
  cursor: 'pointer',
};

const approvedStyle = {
  border: '2px solid green',
};

const notApprovedStyle = {
  border: '2px solid red',
};

const dropdownStyle = {
  backgroundColor: '#F5F5F5',
};
const textStyle = {
  backgroundColor: '#f5f5dc',
};

const WorklistEditItemScreen = () => {
  const { id: worklistId, UKey: UKey, staffID: staffID } = useParams();

  const { userInfo } = useSelector((state) => state.auth);
  const myUserId = userInfo._id;

  //console.log('in WorklistEditItemScreen, myUserId', myUserId);

  const { pageNumber } = useParams();
  const {
    data: myClinicalCommitments,
    isLoading: isLoadingCC,
    error: errorCC,
    refetch: refetchCC,
  } = useGetMyClinicalSCommitmentsQuery(staffID);

  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());

  const handleStartTimeChange = (date) => {
    setStartTime(date);
    setStartHour(date.getHours());
    setStartMin(date.getMinutes());
  };

  const handleEndTimeChange = (date) => {
    setEndTime(date);
    setEndHour(date.getHours());
    setEndMin(date.getMinutes());
  };

  const [activityID, setActivityID] = useState('');
  const [activity, setActivity] = useState('');
  const [clinicalSiteID, setClinicalSiteID] = useState('');
  const [clinicalSite, setClinicalSite] = useState('');
  const [day, setDay] = useState('');
  const [period, setPeriod] = useState('');
  const [startHour, setStartHour] = useState(0);
  const [startMin, setStartMin] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [endMin, setEndMin] = useState(0);
  const [comment, setComment] = useState(0);
  const [isApproved, setIsApproved] = useState(false);

  const {
    data: theItem,
    refetch,
    isLoading,
    error,
  } = useGetWorklistItemsQuery({ worklistID: worklistId, activityID: UKey });

  const {
    data: activitydata,
    isLoading: isLoadingAC,
    error: errorAC,
    refetch: refetchAC,
  } = useGetActivitiesNoPageQuery();
  const {
    data: clinicalSitedata,
    isLoading: isLoadingCS,
    error: errorCS,
    refetch: refetchCS,
  } = useGetClinicalSitesNPQuery();

  const dispatch = useDispatch();

  const [createWorklist, { isLoading: loadingCreate }] =
    useUpdateWorklistActivityMutation(worklistId);

  const createWorklistHandler = async () => {
    if (window.confirm('Are you sure you want to create a new Worklist?')) {
      try {
        await createWorklist();
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const [updateWorklistItem, { isLoading: loadingUpdate }] =
    useUpdateWorklistActivityMutation();

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    //console.log('frontend activity', activity);

    const details = {
      activityID,
      activity,
      clinicalSiteID,
      clinicalSite,
      day,
      period,
      startHour,
      startMin,
      endHour,
      endMin,
      comment,
      isApproved,
    };
    //console.log('details', details);

    //console.log('UKey ', UKey);
    try {
      await updateWorklistItem({
        worklistId,
        details,
        UKey,
      }).unwrap();
      toast.success('Item updated');
      refetch();
      navigate(`/manager/worklist/${worklistId}/edit/${staffID}`); //`/manager/worklist/${worklistId}/edit/${staffID}`   `/worklist/${worklistId}`
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (theItem) {
      setActivityID(theItem.activityID);
      setActivity(theItem.activity);
      setClinicalSiteID(theItem.clinicalSiteID);
      setClinicalSite(theItem.clinicalSite);
      setDay(theItem.day);
      setPeriod(theItem.period);
      setStartHour(theItem.startHour);
      setStartMin(theItem.startMin);
      setEndHour(theItem.endHour);
      setEndMin(theItem.endMin);
      setComment(theItem.comment);

      const newStartDate = new Date();
      newStartDate.setHours(theItem.startHour);
      newStartDate.setMinutes(theItem.startMin);
      setStartTime(newStartDate);

      const newEndDate = new Date();
      newEndDate.setHours(theItem.endHour);
      newEndDate.setMinutes(theItem.endMin);
      setEndTime(newEndDate);

      setIsApproved(theItem.isApproved);
    }
  }, [theItem]);

  return (
    <>
      <Link
        to={`/manager/worklist/${worklistId}/edit/${staffID}`}
        className='btn btn-light my-3'
      >
        Go Back
      </Link>
      <FormContainer>
        <h1>Managers Edit Activity Form...</h1>
        {loadingCreate && <Loader />}
        {loadingUpdate && <Loader />}
        {isLoadingAC && <Loader />}
        {isLoadingCC && <Loader />}
        {isLoadingCS && <Loader />}

        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error.data.message}</Message>
        ) : (
          <div
            style={{
              border: '1px solid #ddd',
              padding: '10px',
              borderRadius: '10px',
              backgroundColor: '#F0F8FF',
            }}
          >
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='activity'>
                <Form.Label>Activity</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Enter activity'
                  value={activity}
                  onChange={(e) => {
                    const selectedOption = e.target.value;
                    //console.log('selectedOption', selectedOption);
                    const [newActivityID, newActivityName] =
                      selectedOption.split(':');
                    setActivityID(newActivityID);
                    setActivity(newActivityName);
                  }}
                >
                  <option value={activity}>{activity}</option>
                  {activitydata &&
                    activitydata
                      .slice()
                      .sort((a, b) =>
                        a.activityName.localeCompare(b.activityName)
                      )
                      .map((activityList) => (
                        <option
                          key={activityList._id}
                          value={`${activityList._id}:${activityList.activityName}`}
                        >
                          {activityList.activityName}
                        </option>
                      ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId='clinicalSite'>
                <Form.Label>Clinical Site</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Enter clinical site'
                  value={clinicalSite}
                  onChange={(e) => {
                    const selectedOption = e.target.value;
                    //console.log('selectedOption', selectedOption);
                    const [newClinicalSiteID, newClinicalSiteName] =
                      selectedOption.split(':');
                    setClinicalSiteID(newClinicalSiteID);
                    setClinicalSite(newClinicalSiteName);
                  }}
                >
                  <option value={clinicalSite}>{clinicalSite}</option>
                  {myClinicalCommitments &&
                    myClinicalCommitments.clinicalSCommitments &&
                    myClinicalCommitments.clinicalSCommitments
                      .slice()
                      .sort((a, b) =>
                        a.clinicalSiteName.localeCompare(b.clinicalSiteName)
                      )
                      .map((clinicalSiteList) => (
                        <option
                          key={clinicalSiteList._id}
                          value={`${clinicalSiteList._id}:${clinicalSiteList.clinicalSiteName}`}
                        >
                          {clinicalSiteList.clinicalSiteName}
                        </option>
                      ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId='day'>
                <Form.Label>Enter day</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Enter day'
                  value={day}
                  onChange={(e) => setDay(e.target.value)}
                  style={dropdownStyle}
                >
                  <option value={day}>{day}</option>
                  <option value='1.Monday'>Monday</option>
                  <option value='2.Tuesday'>Tuesday</option>
                  <option value='3.Wednesday'>Wednesday</option>
                  <option value='4.Thursday'>Thursday</option>
                  <option value='5.Friday'>Friday</option>
                  <option value='6.Saturday'>Saturday</option>
                  <option value='7.Sunday'>Sunday</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId='period'>
                <Form.Label>
                  Enter Frequency <ModalScreen />
                </Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Enter Frequency'
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                  style={dropdownStyle}
                >
                  <option value={period}>{period}</option>
                  <option value='Each Week'>Each Week</option>
                  <option value='For Noting, Biweekly/Triweekly task (see comments for details)'>
                    For Noting, Biweekly/Triweekly task
                  </option>
                </Form.Control>
                <Form.Group controlId='comments'>
                  <Form.Label>Comments</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Comments'
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    style={textStyle}
                  ></Form.Control>
                </Form.Group>
              </Form.Group>

              <Form.Group controlId='startTime'>
                <Form.Label>Start Time</Form.Label>
                <DatePicker
                  selected={startTime}
                  onChange={handleStartTimeChange}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption='Time'
                  timeFormat='HH:mm'
                  dateFormat='HH:mm'
                  className='custom-date-picker'
                />
              </Form.Group>

              <Form.Group controlId='endTime'>
                <Form.Label>End Time</Form.Label>
                <DatePicker
                  selected={endTime}
                  onChange={handleEndTimeChange}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption='Time'
                  dateFormat='HH:mm'
                  timeFormat='HH:mm'
                  className='custom-date-picker'
                />
              </Form.Group>

              <Form.Group controlId='isApproved'>
                <Form.Label>Is Approved?</Form.Label>
                <div
                  style={{
                    ...checkboxStyle,
                    ...(isApproved ? approvedStyle : notApprovedStyle),
                  }}
                  onClick={() => setIsApproved(!isApproved)}
                >
                  {isApproved ? (
                    <FaCheck style={{ color: 'green' }} />
                  ) : (
                    <FaTimes style={{ color: 'red' }} />
                  )}
                </div>
              </Form.Group>

              <Button
                type='submit'
                variant='primary'
                style={{ marginTop: '1rem' }}
              >
                Update
              </Button>
            </Form>
          </div>
        )}
      </FormContainer>
    </>
  );
};

export default WorklistEditItemScreen;
