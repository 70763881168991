import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { startAuthTimer, clearAuthTimer } from '../utils/authTimer';
import { useSelector } from 'react-redux';

const resetTimer = () => {

  const expirationTime = new Date().getTime() + 2 * 60 * 1000; // 5 minutes
      localStorage.setItem('expirationTime', expirationTime);
   };

const AuthMonitor = () => {
    const userInfo = useSelector((state) => state.auth.userInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo) {

      // Set up event listeners
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);
    window.addEventListener('click', resetTimer);

    // Start the initial timer
    startAuthTimer(dispatch, navigate);

    // Cleanup event listeners on unmount
    return () => {
      clearAuthTimer(); // Clear on unmount
      
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
      window.removeEventListener('click', resetTimer);
    };
    
    
    }
  }, [dispatch, navigate, userInfo]);

  return null;
};



export default AuthMonitor;