import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
//import FormContainer from '../components/FormContainer';

//import { useRegisterMutation } from '../slices/usersApiSlice';
import { usePasswordResetMutation } from '../slices/usersApiSlice';
//import { setCredentials } from '../slices/authSlice';
import { toast } from 'react-toastify';
import { logout } from '../slices/authSlice';

import { resetCart } from '../slices/cartSlice';
//import { useCookies } from 'react-cookie';

//#f5f5dc

const textStyle = {
  backgroundColor: '#f5f5dc',
  // Add other styles as needed
};

const PasswordResetScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetToken, setResetToken] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passwordreset, { isLoading }] = usePasswordResetMutation();
  //const [register, { isLoading }] = useRegisterMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect') || '/';

  // Access the 't' parameter
  const t = sp.get('t');
  //console.log("reset sereen t oo- ", t)

  if (t == null) {
    navigate('/login');
  }

  // cookie access is backend only
  // const [cookies] = useCookies(['pset']);
  //const psetValue = cookies.pset || ''; // Access the value of the "pset" cookie

  useEffect(() => {
    if (userInfo && t) {
      navigate(redirect);
    }
    // Set the reset token only once when the component mounts
    if (t !== null) {
      setResetToken(t);
    }
  }, [navigate, redirect, userInfo, t]);

  const submitHandler = async (e) => {
    e.preventDefault();

    //console.log("reset sereen, email - ", email)
    //console.log("reset sereen, password - ", password)
    //console.log('reset sereen, Token - :', resetToken);

    if (password !== confirmPassword || !email) {
      toast.error('Passwords do not match or Email missing');
    } else {
      try {
        //const res =
        //await passwordreset({ email, password }).unwrap();
        const res = await passwordreset({
          email,
          password,
          resetToken,
        }).unwrap();
        dispatch(logout());
        // NOTE: here we need to reset cart state for when a user logs out so the next
        // user doesn't inherit the previous users cart and shipping
        dispatch(resetCart());
        navigate('/login');
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <Container fluid>
      <h1>Register</h1>
      <Form onSubmit={submitHandler}>
        <Row>
          <Col md={4}>
            <div
              style={{
                border: '1px solid #ddd',
                padding: '10px',
                borderRadius: '10px',
                backgroundColor: '#F0F8FF',
              }}
            >
              <Form.Group className='my-2' controlId='email'>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={textStyle}
                ></Form.Control>
              </Form.Group>
            </div>
          </Col>

          {/* Third Column **************************************************************************************/}
          <Col md={4}>
            <div
              style={{
                border: '1px solid #ddd',
                padding: '10px',
                borderRadius: '10px',
                backgroundColor: '#F0F8FF',
              }}
            >
              <Form.Group className='my-2' controlId='password'>
                <Form.Label>Change Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Set password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={textStyle}
                ></Form.Control>
              </Form.Group>

              <Form.Group className='my-2' controlId='confirmPassword'>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Confirm password'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={textStyle}
                ></Form.Control>
              </Form.Group>
            </div>
          </Col>
        </Row>

        <Button disabled={isLoading} type='submit' variant='primary'>
          Reset Password
        </Button>

        {isLoading && <Loader />}
      </Form>

      <Row className='py-3'>
        <Col>
          go to login{' '}
          <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
            Login
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordResetScreen;
