import { Navbar, Nav, Container, NavDropdown, Badge } from 'react-bootstrap';
import { FaShoppingCart, FaUser } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../slices/usersApiSlice';
import { logout } from '../slices/authSlice';
import SearchBox from './SearchBox';
import logo from '../assets/BeaCrest.jpg';
import { resetCart } from '../slices/cartSlice';
import WorkVolumeChart from '../components/chartModal_test.jsx';

const Header = () => {
  const dropdownMenuStyle = {
    zIndex: 1050,
    backgroundColor: 'lightblue',
  };

  const dropdownItemStyle = {
    backgroundColor: 'lightyellow',
  };

  const { cartItems } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      // NOTE: here we need to reset cart state for when a user logs out so the next
      // user doesn't inherit the previous users cart and shipping
      dispatch(resetCart());
      navigate('/login');
    } catch (err) {
      console.error(err);
    }
  };

  //<Navbar bg='primary' variant='dark' expand='lg' collapseOnSelect>
  //<Containerfluid className='form-container'>   strech container full width

  //console.log('userInfo', userInfo);

  return (
    <header>
      <Navbar
        style={{ backgroundColor: '#b1e1e7', height: '80px' }}
        variant='light'
        expand='lg'
        collapseOnSelect
      >
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>
              <img
                src={logo}
                alt='Beaumont Hospital Portal'
                style={{ maxHeight: '60px', maxWidth: '100%', height: 'auto' }}
              />

              <Navbar.Text style={{ fontSize: '30px', marginLeft: '30px' }}>
                Beaumont Hospital Portal
              </Navbar.Text>
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ms-auto'>
              <SearchBox />
              {/*
              <LinkContainer to='/cart'>
                <Nav.Link>
                  <FaShoppingCart /> Cart
                  {cartItems.length > 0 && (
                    <Badge pill bg='success' style={{ marginLeft: '5px' }}>
                      {cartItems.reduce((a, c) => a + c.qty, 0)}
                    </Badge>
                  )}
                </Nav.Link>
              </LinkContainer>
                  */}

              {userInfo ? (
                <>
                  <NavDropdown
                    title={userInfo.name}
                    id='username'
                    style={dropdownMenuStyle}
                  >
                    <LinkContainer to='/profile'>
                      <NavDropdown.Item style={dropdownItemStyle}>
                        Profile
                      </NavDropdown.Item>
                    </LinkContainer>

                    <LinkContainer to='/clinicalSCommitmentlist'>
                      <NavDropdown.Item style={dropdownItemStyle}>
                        My Clinical Commitments
                      </NavDropdown.Item>
                    </LinkContainer>

                    <LinkContainer to='/spqualList'>
                      <NavDropdown.Item style={dropdownItemStyle}>
                        My Specialised Expertise - Advanced Competencies
                      </NavDropdown.Item>
                    </LinkContainer>

                    <LinkContainer to='/worklist'>
                      <NavDropdown.Item style={dropdownItemStyle}>
                        My Worklist
                      </NavDropdown.Item>
                    </LinkContainer>

                    <NavDropdown.Item onClick={logoutHandler}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <LinkContainer to='/login'>
                  <Nav.Link>
                    <FaUser /> Sign In
                  </Nav.Link>
                </LinkContainer>
              )}
              {/* manager Links */}
              {userInfo && userInfo.isManager && (
                <NavDropdown
                  title='Manager'
                  id='managermenu'
                  style={dropdownMenuStyle}
                >
                  <LinkContainer to='/manager/approveWorklist'>
                    <NavDropdown.Item style={dropdownItemStyle}>
                      Approve User Worklists
                    </NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/manager/approvedWorklist'>
                    <NavDropdown.Item style={dropdownItemStyle}>
                      Approved / Part Approved
                    </NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/manager/assignment'>
                    <NavDropdown.Item style={dropdownItemStyle}>
                      Assign Privileges
                    </NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/manager/assignmentView'>
                    <NavDropdown.Item style={dropdownItemStyle}>
                      View Assigned Privileges
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
              {/* Admin Links */}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown
                  title='Admin'
                  id='adminmenu'
                  style={dropdownMenuStyle}
                >
                  {/*
                  <LinkContainer to='/admin/orderlist'>
                    <NavDropdown.Item style={dropdownItemStyle}>Orders</NavDropdown.Item>
                  </LinkContainer>
              */}
                  <LinkContainer to='/admin/userlist'>
                    <NavDropdown.Item style={dropdownItemStyle}>
                      Users
                    </NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/admin/activitylist'>
                    <NavDropdown.Item style={dropdownItemStyle}>
                      Activities
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/clinicalsitelist'>
                    <NavDropdown.Item style={dropdownItemStyle}>
                      Clinical Sites
                    </NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/admin/directoratelist'>
                    <NavDropdown.Item style={dropdownItemStyle}>
                      Directorates
                    </NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/admin/specialitylist'>
                    <NavDropdown.Item style={dropdownItemStyle}>
                      Speciality
                    </NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/admin/privileges'>
                    <NavDropdown.Item style={dropdownItemStyle}>
                      Privileges
                    </NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/admin/privilege/assignment'>
                    <NavDropdown.Item style={dropdownItemStyle}>
                      Assign Privileges
                    </NavDropdown.Item>
                  </LinkContainer>

                  {/*
                  <LinkContainer to='/admin/locationlist'>
                    <NavDropdown.Item style={dropdownItemStyle}>
                      Locations within Directorates
                    </NavDropdown.Item>
                  </LinkContainer>

                  

                  <LinkContainer to='/admin/clinicaldirectorlist'>
                    <NavDropdown.Item style={dropdownItemStyle}>Clinical Directors</NavDropdown.Item>
                  </LinkContainer>

                  */}

                  <LinkContainer to='/admin/clinicalSCommitmentlist'>
                    <NavDropdown.Item style={dropdownItemStyle}>
                      Clinical Commitments
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
              {/* Rad Admin Links */}
              {userInfo && userInfo.isRadAdmin && (
                <NavDropdown
                  title='Radiation Admin'
                  id='radadminmenu'
                  style={dropdownMenuStyle}
                >
                  <LinkContainer to='/admin/RadAdmin/radiationsafetylist'>
                    <NavDropdown.Item style={dropdownItemStyle}>
                      Radiation Safety Records
                    </NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/Admin/userlist/Rad'>
                    <NavDropdown.Item style={dropdownItemStyle}>
                      Users
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}

              {/* Menu Links */}
              {userInfo && userInfo.name === 'andrew redmond' && (
                <NavDropdown title='Menus' id='menus' style={dropdownMenuStyle}>
                  <LinkContainer to='/admin/productlist'>
                    <NavDropdown.Item style={dropdownItemStyle}>
                      Menu Items
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
