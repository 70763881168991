import { Chart as ChartJS } from 'chart.js/auto';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
// import ChartDataLabels from 'chartjs-plugin-datalabels'; // No longer needed
import React, { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Message from './Message.jsx';
import Loader from './Loader.jsx';
import { useGetAggregateWorkDataActivityQuery } from '../slices/worklistsApiSlice.js';
import { FaLayerGroup } from 'react-icons/fa';

import '../utils/tableStack.css';

// Register the plugin to all charts:
// ChartJS.register(ChartDataLabels); // No longer needed
ChartJS.register(TreemapController, TreemapElement);

/*
ChartJS.defaults.set('plugins.datalabels', {
  anchor: 'end', // default anchor position for datalabels
  color: 'green', // default text color for datalabels
  formatter: function (value, context) {
    return context.chart.data.labels[context.dataIndex]; // custom formatter
  },
  display: true, // default display state for datalabels
});
*/

function WorkVolumeChartTreeMap({ userId }) {
  console.log('TreeMap WorkVolumeChart : userId', userId);
  const [show, setShow] = useState(false);
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [isChartReady, setIsChartReady] = useState(false);

  const {
    data: aggregateWorkDataTreeMap = [],
    isLoading,
    error,
    refetch,
  } = useGetAggregateWorkDataActivityQuery(userId);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    if (chartInstance) {
      chartInstance.destroy();
      setChartInstance(null);
    }
    setShow(false);
  };

  useEffect(() => {
    if (show && chartRef.current && aggregateWorkDataTreeMap.length > 0) {
      const topTags2 = aggregateWorkDataTreeMap.map((item) => ({
        tag: item.activity,
        theWorkTime: item.theWorkTime,
      }));
      console.log('topTags2 ..', topTags2);

      const config = {
        type: 'treemap',
        data: {
          datasets: [
            {
              label: 'Activity Chart',
              tree: topTags2,
              key: 'theWorkTime',
              groups: ['tag'],
              spacing: 0.5,
              borderWidth: 1.5,
              borderColor: 'grey',
              backgroundColor: (ctx) => {
                console.log('backgroundColor: ctx', ctx);

                if (
                  !ctx ||
                  !ctx.raw ||
                  !ctx.raw._data ||
                  !ctx.raw._data.theWorkTime
                ) {
                  return 'rgba(0, 128, 0, 0.1)';
                }
                const alpha = (1 + Math.log(ctx.raw._data.theWorkTime)) / 5;
                return `rgba(0, 128, 0, ${alpha})`;
              },

              labels: {
                display: true,
                formatter: (ctx) => {
                  if (ctx && ctx.raw._data.tag && ctx.raw._data.theWorkTime) {
                    return `${ctx.raw._data.tag} - ${ctx.raw._data.theWorkTime}`;
                  }
                  return 'blank'; // Return 'blank' if tag or theWorkTime is undefined
                },
                color: 'black',
                font: {
                  size: 12,
                },
                align: 'center',
                anchor: 'center',
                clip: true,
                overflow: 'fit',
              },
            },
          ],
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: 'Activity Chart',
            },
            legend: {
              display: false,
            },
          },
        },
      }; // config

      const newChartInstance = new ChartJS(chartRef.current, config);
      setChartInstance(newChartInstance);
    }

    // Indicate that the chart is ready
    setIsChartReady(true);

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
        setChartInstance(null);
      }
    };
  }, [show, aggregateWorkDataTreeMap]);

  const overallTotalWorkTimeInMinutes = aggregateWorkDataTreeMap.reduce(
    (total, item) => total + item.theWorkTime * 60,
    0
  );

  const overallTotalWorkHours = Math.floor(overallTotalWorkTimeInMinutes / 60);
  const overallTotalWorkMinutes = overallTotalWorkTimeInMinutes % 60;

  const handlePrint = () => {
    if (isChartReady && chartRef.current) {
      const chart = chartRef.current;
      if (chart) {
        const canvas = chart.canvas;
        const dataUrl = canvas.toDataURL();
        const printWindow = window.open('', '_blank');
        printWindow.document.write(
          '<html><head><title>Print Chart</title></head><body>'
        );
        printWindow.document.write(
          `<img src="${dataUrl}" onload="window.print();window.close()" />`
        );
        printWindow.document.write('</body></html>');
        printWindow.document.close();
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {isLoading ? (
        <>
          <Loader />
          <Loader /> {/* Second loader */}
        </>
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Button variant='outline-info' size='sm' onClick={handleShow}>
            <FaLayerGroup />
            Activity Chart
          </Button>

          <Modal
            show={show}
            onHide={handleClose}
            style={{ width: '100%', maxWidth: 'none', height: '100%' }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h6>
                  Weekly Chart - Work Time: {overallTotalWorkHours} hours{' '}
                  {overallTotalWorkMinutes} minutes
                </h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {console.log(
                'aggregateWorkDataTreeMap...',
                aggregateWorkDataTreeMap
              )}

              {aggregateWorkDataTreeMap.length > 0 ? (
                <>
                  <canvas ref={chartRef} />
                  <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={handlePrint}
                  >
                    Print Chart
                  </Button>
                </>
              ) : (
                <Message variant='info'>No data available</Message>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default WorkVolumeChartTreeMap;
