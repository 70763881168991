// authTimer.js
import { logout } from '../slices/authSlice';

let timerId;

export const startAuthTimer = (dispatch, navigate) => {
  clearAuthTimer(); // Clear any existing timers

  timerId = setInterval(() => {
    
   //console.log("checking the timer", timerId)
    const expirationTime = localStorage.getItem('expirationTime');

    if (!expirationTime) {
      return;
    }
    const currentTime = new Date().getTime();

    if (expirationTime && currentTime > expirationTime) {
      
      dispatch(logout());
      clearAuthTimer(); // Clear any existing timers
      navigate('/login');
    }
  }, 60000);
};

export const clearAuthTimer = () => {
    //console.log("clearing the timer", timerId)
  if (timerId) {
    clearInterval(timerId);
   //console.log("cleared timer", timerId)
  }
};
