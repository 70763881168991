import { LOCATION_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const LocationApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getLocations: builder.query({
            query: ({ keyword, pageNumber }) => ({
                url: LOCATION_URL,
                params: { keyword, pageNumber },
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Locations'],
        }),

        getLocationsNP: builder.query({
            query: () => ({
                url: `${LOCATION_URL}/list`,

            }),
            keepUnusedDataFor: 5,
            providesTags: ['Locations'],
        }),



        getLocationDetails: builder.query({
            query: (LocationId) => ({
                url: `${LOCATION_URL}/${LocationId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        createLocation: builder.mutation({
            query: () => ({
                url: `${LOCATION_URL}`,
                method: 'POST',
            }),
            invalidatesTags: ['Location'],
        }),
        updateLocation: builder.mutation({
            query: (data) => ({
                url: `${LOCATION_URL}/${data.LocationId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Location'],
        }),
        deleteLocation: builder.mutation({
            query: (LocationId) => ({
                url: `${LOCATION_URL}/${LocationId}`,
                method: 'DELETE',
            }),
            providesTags: ['Location'],
        }),


    }),
});

export const {
    useGetLocationsQuery,
    useGetLocationsNPQuery,
    useGetLocationDetailsQuery,
    useCreateLocationMutation,
    useUpdateLocationMutation,
    useDeleteLocationMutation,
} = LocationApiSlice;