import { SPQUALS_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const spqualsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSPQuals: builder.query({
            query: ({ keyword, pageNumber }) => ({
                url: SPQUALS_URL,
                params: { keyword, pageNumber },
            }),
            keepUnusedDataFor: 5,
            providesTags: ['SPQuals'],
        }),


        
        getMySPQuals: builder.query({
            query: (id) => ({
                url: `${SPQUALS_URL}/mine/${id}`,
            }),
            keepUnusedDataFor: 5,
        }),




        getSPQualDetails: builder.query({
            query: (SPQualId) => ({
                url: `${SPQUALS_URL}/${SPQualId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        createSPQual: builder.mutation({
            query: () => ({
                url: `${SPQUALS_URL}`,
                method: 'POST',
            }),
            invalidatesTags: ['SPQual'],
        }),
        updateSPQual: builder.mutation({
            query: (data) => ({
                url: `${SPQUALS_URL}/${data.SPQualId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['SPQual'],
        }),
        deleteSPQual: builder.mutation({
            query: (SPQualId) => ({
                url: `${SPQUALS_URL}/${SPQualId}`,
                method: 'DELETE',
            }),
            providesTags: ['SPQual'],
        }),


    }),
});

export const {
    useGetSPQualsQuery,
    useGetMySPQualsQuery,
    useGetSPQualDetailsQuery,
    useCreateSPQualMutation,
    useUpdateSPQualMutation,
    useDeleteSPQualMutation,
} = spqualsApiSlice;
