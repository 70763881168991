import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';

function ModalSPQ() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //  style={{ whiteSpace: 'nowrap', width: '600px' }} for <td> on table
  return (
    <>
      <Button variant='outline-info' size='sm' onClick={handleShow}>
        Notes
      </Button>

      <Modal show={show} onHide={handleClose} size='lg'>
        {' '}
        {/* Add size="lg" prop here */}
        <Modal.Header closeButton>
          <Modal.Title>Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Examples of areas of expertise enable doctors in various specialties
            to offer more advanced, personalised, and comprehensive care to
            their patients.
          </div>

          <Table
            striped
            bordered
            hover
            responsive
            className='table-sm table-responsive-sm'
          >
            <thead>
              <tr>
                <th>Category</th>
                <th>Specialty</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan='20'>General Subspecialties</td>
                <td>Subspecialty Certifications</td>
                <td>e.g., Cardiology within Internal Medicine</td>
              </tr>
              <tr>
                <td>Fellowship Training</td>
                <td>e.g., Pediatric Endocrinology within Pediatrics</td>
              </tr>
              <tr>
                <td>Advanced Surgical Techniques</td>
                <td>e.g., Robotic surgery, minimally invasive procedures</td>
              </tr>
              <tr>
                <td>Expertise in Rare Diseases</td>
                <td>e.g., Treatment of rare genetic disorders</td>
              </tr>
              <tr>
                <td>Proficiency in Medical Imaging</td>
                <td>e.g., MRI, CT interpretation beyond the usual scope</td>
              </tr>
              <tr>
                <td>Interventional Procedures</td>
                <td>
                  e.g., Interventional radiology, catheter-based interventions
                </td>
              </tr>
              <tr>
                <td>Advanced Pain Management Techniques</td>
                <td>e.g., Epidural injections, nerve blocks</td>
              </tr>
              <tr>
                <td>Telemedicine Expertise</td>
                <td>e.g., Virtual consultations, remote diagnostics</td>
              </tr>
              <tr>
                <td>Research Methodology and Clinical Trials</td>
                <td>e.g., Designing and leading clinical trials</td>
              </tr>
              <tr>
                <td>Public Health Expertise</td>
                <td>e.g., Epidemiology, healthcare policy</td>
              </tr>
              <tr>
                <td>Medical Education and Training</td>
                <td>e.g., Teaching methods, curriculum development</td>
              </tr>
              <tr>
                <td>Bioethics and Medical Law</td>
                <td>e.g., Ethical decision-making in complex cases</td>
              </tr>
              <tr>
                <td>Integrative and Complementary Medicine</td>
                <td>e.g., Acupuncture, herbal medicine</td>
              </tr>
              <tr>
                <td>Genetic Counseling and Genomics</td>
                <td>e.g., Personalized medicine, gene therapy</td>
              </tr>
              <tr>
                <td>Health Informatics</td>
                <td>e.g., Electronic health records, data management</td>
              </tr>
              <tr>
                <td>Disaster Medicine and Emergency Preparedness</td>
                <td>e.g., Crisis management, mass casualty response</td>
              </tr>
              <tr>
                <td>Palliative Care and End-of-Life Expertise</td>
                <td>e.g., Symptom management, hospice care</td>
              </tr>
              <tr>
                <td>Advanced Pharmacology</td>
                <td>e.g., Complex medication management, pharmacogenomics</td>
              </tr>
              <tr>
                <td>Global Health and Tropical Medicine</td>
                <td>e.g., Infectious diseases in low-resource settings</td>
              </tr>
              <tr>
                <td>Holistic and Preventative Medicine</td>
                <td>e.g., Lifestyle medicine, preventive care strategies</td>
              </tr>
              <tr>
                <td rowspan='14'>Neurology</td>
                <td>Neurocritical Care</td>
                <td>
                  e.g., Management of severe brain injuries, stroke, and
                  neurotrauma
                </td>
              </tr>
              <tr>
                <td>Epileptology</td>
                <td>
                  e.g., Advanced management of epilepsy, surgical treatment of
                  seizures
                </td>
              </tr>
              <tr>
                <td>Movement Disorders</td>
                <td>
                  e.g., Treatment of Parkinson's disease, tremors, dystonia
                </td>
              </tr>
              <tr>
                <td>Neuroimmunology</td>
                <td>
                  e.g., Multiple sclerosis, autoimmune neurological disorders
                </td>
              </tr>
              <tr>
                <td>Neuromuscular Medicine</td>
                <td>e.g., Diagnosis and treatment of ALS, myasthenia gravis</td>
              </tr>
              <tr>
                <td>Headache Medicine</td>
                <td>
                  e.g., Specialized treatment for chronic migraines and cluster
                  headaches
                </td>
              </tr>
              <tr>
                <td>Behavioral Neurology and Neuropsychiatry</td>
                <td>
                  e.g., Alzheimer's disease, dementia, cognitive disorders
                </td>
              </tr>
              <tr>
                <td>Neurorehabilitation</td>
                <td>
                  e.g., Recovery strategies post-stroke, brain injury
                  rehabilitation
                </td>
              </tr>
              <tr>
                <td>Neurogenetics</td>
                <td>
                  e.g., Genetic counseling and treatment for hereditary
                  neurological disorders
                </td>
              </tr>
              <tr>
                <td>Sleep Medicine</td>
                <td>
                  e.g., Treatment of sleep disorders like narcolepsy, sleep
                  apnea
                </td>
              </tr>
              <tr>
                <td>Pediatric Neurology</td>
                <td>
                  e.g., Neurological disorders in children, developmental
                  disorders
                </td>
              </tr>
              <tr>
                <td>Clinical Neurophysiology</td>
                <td>e.g., EEG, EMG, and nerve conduction studies</td>
              </tr>
              <tr>
                <td>Neuro-oncology</td>
                <td>e.g., Treatment of brain and spinal cord tumors</td>
              </tr>
              <tr>
                <td>Pain Management</td>
                <td>
                  e.g., Management of chronic pain related to neurological
                  conditions
                </td>
              </tr>
              <tr>
                <td rowspan='12'>Urology</td>
                <td>Urologic Oncology</td>
                <td>
                  e.g., Surgical and medical treatment of prostate, bladder, and
                  kidney cancers
                </td>
              </tr>
              <tr>
                <td>Male Infertility</td>
                <td>
                  e.g., Advanced treatments for male reproductive issues,
                  microsurgery
                </td>
              </tr>
              <tr>
                <td>Female Urology</td>
                <td>e.g., Pelvic floor dysfunction, urinary incontinence</td>
              </tr>
              <tr>
                <td>Pediatric Urology</td>
                <td>
                  e.g., Congenital abnormalities, urologic disorders in children
                </td>
              </tr>
              <tr>
                <td>Endourology</td>
                <td>
                  e.g., Minimally invasive techniques for kidney stones,
                  ureteroscopy
                </td>
              </tr>
              <tr>
                <td>Laparoscopic and Robotic Urology</td>
                <td>
                  e.g., Minimally invasive surgery for urologic conditions
                </td>
              </tr>
              <tr>
                <td>Andrology</td>
                <td>
                  e.g., Male sexual health, erectile dysfunction, testosterone
                  replacement therapy
                </td>
              </tr>
              <tr>
                <td>Renal Transplantation</td>
                <td>e.g., Kidney transplant surgery and postoperative care</td>
              </tr>
              <tr>
                <td>Neuro-urology</td>
                <td>
                  e.g., Urinary disorders related to neurological conditions,
                  bladder dysfunction
                </td>
              </tr>
              <tr>
                <td>Reconstructive Urology</td>
                <td>e.g., Urethral stricture surgery, urinary diversion</td>
              </tr>
              <tr>
                <td>Prosthetics and Implants</td>
                <td>e.g., Penile implants, artificial urinary sphincters</td>
              </tr>
              <tr>
                <td>Urodynamics</td>
                <td>e.g., Advanced diagnostic testing for urinary function</td>
              </tr>
              <tr>
                <td rowspan='15'>Surgery</td>
                <td>Trauma Surgery</td>
                <td>
                  e.g., Emergency surgery for traumatic injuries, complex wound
                  management
                </td>
              </tr>
              <tr>
                <td>Transplant Surgery</td>
                <td>e.g., Liver, kidney, heart, and lung transplants</td>
              </tr>
              <tr>
                <td>Minimally Invasive Surgery</td>
                <td>e.g., Laparoscopy, endoscopy, robotic-assisted surgery</td>
              </tr>
              <tr>
                <td>Bariatric Surgery</td>
                <td>e.g., Weight loss surgery, metabolic surgery</td>
              </tr>
              <tr>
                <td>Surgical Oncology</td>
                <td>e.g., Removal of tumors, cancer staging surgeries</td>
              </tr>
              <tr>
                <td>Vascular Surgery</td>
                <td>e.g., Aneurysm repair, vascular bypass procedures</td>
              </tr>
              <tr>
                <td>Cardiothoracic Surgery</td>
                <td>
                  e.g., Heart valve repair, coronary artery bypass grafting
                </td>
              </tr>
              <tr>
                <td>Pediatric Surgery</td>
                <td>
                  e.g., Surgery for congenital abnormalities, neonatal surgery
                </td>
              </tr>
              <tr>
                <td>Plastic and Reconstructive Surgery</td>
                <td>
                  e.g., Cosmetic procedures, reconstructive surgery after trauma
                  or cancer
                </td>
              </tr>
              <tr>
                <td>Colorectal Surgery</td>
                <td>
                  e.g., Treatment of colorectal cancer, inflammatory bowel
                  disease
                </td>
              </tr>
              <tr>
                <td>Hepatobiliary Surgery</td>
                <td>e.g., Surgery of the liver, pancreas, and bile ducts</td>
              </tr>
              <tr>
                <td>Endocrine Surgery</td>
                <td>e.g., Thyroidectomy, adrenalectomy</td>
              </tr>
              <tr>
                <td>Orthopedic Surgery</td>
                <td>e.g., Joint replacement, sports injuries, spine surgery</td>
              </tr>
              <tr>
                <td>Neurosurgery</td>
                <td>
                  e.g., Brain and spinal cord surgery, treatment of
                  neurovascular disorders
                </td>
              </tr>
              <tr>
                <td>Critical Care Surgery</td>
                <td>e.g., Management of surgical patients in the ICU</td>
              </tr>
              <tr>
                <td rowspan='12'>General Internal Medicine</td>
                <td>Geriatric Medicine</td>
                <td>
                  e.g., Management of complex medical issues in elderly patients
                </td>
              </tr>
              <tr>
                <td>Palliative Care</td>
                <td>e.g., Symptom management and end-of-life care</td>
              </tr>
              <tr>
                <td>Hospital Medicine</td>
                <td>
                  e.g., Expertise in managing hospitalized patients, inpatient
                  care
                </td>
              </tr>
              <tr>
                <td>Preventive Medicine</td>
                <td>
                  e.g., Screening and risk reduction strategies for chronic
                  diseases
                </td>
              </tr>
              <tr>
                <td>Infectious Diseases</td>
                <td>
                  e.g., Diagnosis and treatment of complex infections, HIV/AIDS
                  care
                </td>
              </tr>
              <tr>
                <td>Travel Medicine</td>
                <td>
                  e.g., Pre-travel vaccinations and disease prevention,
                  post-travel disease management
                </td>
              </tr>
              <tr>
                <td>Health Informatics</td>
                <td>
                  e.g., Use of electronic health records, data analytics in
                  patient care
                </td>
              </tr>
              <tr>
                <td>Nutrition and Metabolism</td>
                <td>
                  e.g., Management of nutritional disorders, metabolic diseases
                </td>
              </tr>
              <tr>
                <td>Addiction Medicine</td>
                <td>
                  e.g., Treatment of substance use disorders, detoxification
                  protocols
                </td>
              </tr>
              <tr>
                <td>Women's Health</td>
                <td>
                  e.g., Management of issues specific to female patients,
                  reproductive health
                </td>
              </tr>
              <tr>
                <td>Clinical Pharmacology</td>
                <td>e.g., Complex medication management, drug interactions</td>
              </tr>
              <tr>
                <td>Diagnostic Reasoning</td>
                <td>e.g., Expertise in complex and rare disease diagnosis</td>
              </tr>
              <tr>
                <td rowspan='7'>Cardiology</td>
                <td>Interventional Cardiology</td>
                <td>
                  e.g., Angioplasty, stent placement, catheter-based heart
                  procedures
                </td>
              </tr>
              <tr>
                <td>Electrophysiology</td>
                <td>
                  e.g., Management of heart rhythm disorders, pacemaker
                  implantation
                </td>
              </tr>
              <tr>
                <td>Heart Failure and Transplant Cardiology</td>
                <td>
                  e.g., Advanced heart failure management, heart transplant care
                </td>
              </tr>
              <tr>
                <td>Preventive Cardiology</td>
                <td>
                  e.g., Strategies to reduce heart disease risk, cholesterol
                  management
                </td>
              </tr>
              <tr>
                <td>Cardiac Imaging</td>
                <td>e.g., Advanced echocardiography, cardiac MRI, and CT</td>
              </tr>
              <tr>
                <td>Adult Congenital Heart Disease</td>
                <td>e.g., Treatment of congenital heart defects in adults</td>
              </tr>
              <tr>
                <td>Sports Cardiology</td>
                <td>
                  e.g., Cardiovascular care for athletes, exercise testing
                </td>
              </tr>
              <tr>
                <td rowspan='8'>Endocrinology</td>
                <td>Diabetes Management</td>
                <td>
                  e.g., Advanced insulin therapy, continuous glucose monitoring
                </td>
              </tr>
              <tr>
                <td>Thyroid Disorders</td>
                <td>
                  e.g., Treatment of hyperthyroidism, hypothyroidism, and
                  thyroid nodules
                </td>
              </tr>
              <tr>
                <td>Bone and Mineral Metabolism</td>
                <td>e.g., Management of osteoporosis, calcium disorders</td>
              </tr>
              <tr>
                <td>Pituitary Disorders</td>
                <td>
                  e.g., Management of pituitary adenomas, hormonal imbalances
                </td>
              </tr>
              <tr>
                <td>Adrenal Disorders</td>
                <td>
                  e.g., Diagnosis and treatment of Cushing's syndrome, Addison's
                  disease
                </td>
              </tr>
              <tr>
                <td>Reproductive Endocrinology</td>
                <td>
                  e.g., Treatment of infertility, polycystic ovary syndrome
                </td>
              </tr>
              <tr>
                <td>Obesity Medicine</td>
                <td>e.g., Medical management of obesity, metabolic syndrome</td>
              </tr>
              <tr>
                <td>Lipid Disorders</td>
                <td>
                  e.g., Management of high cholesterol, triglyceride
                  abnormalities
                </td>
              </tr>
              <tr>
                <td rowspan='7'>Gastroenterology</td>
                <td>Hepatology</td>
                <td>
                  e.g., Management of liver diseases, cirrhosis, liver
                  transplantation
                </td>
              </tr>
              <tr>
                <td>Inflammatory Bowel Disease (IBD)</td>
                <td>e.g., Treatment of Crohn's disease, ulcerative colitis</td>
              </tr>
              <tr>
                <td>Advanced Endoscopy</td>
                <td>
                  e.g., ERCP, EUS, and other specialized endoscopic procedures
                </td>
              </tr>
              <tr>
                <td>Pancreatic Disorders</td>
                <td>e.g., Management of pancreatitis, pancreatic cancer</td>
              </tr>
              <tr>
                <td>Esophageal Disorders</td>
                <td>e.g., Treatment of GERD, Barrett's esophagus</td>
              </tr>
              <tr>
                <td>Gastrointestinal Motility Disorders</td>
                <td>
                  e.g., Management of gastroparesis, irritable bowel syndrome
                </td>
              </tr>
              <tr>
                <td>Nutrition and Intestinal Rehabilitation</td>
                <td>
                  e.g., Management of short bowel syndrome, enteral and
                  parenteral nutrition
                </td>
              </tr>
              <tr>
                <td rowspan='7'>Pulmonology</td>
                <td>Critical Care Medicine</td>
                <td>
                  e.g., Management of patients in the ICU, mechanical
                  ventilation
                </td>
              </tr>
              <tr>
                <td>Sleep Medicine</td>
                <td>
                  e.g., Treatment of sleep apnea, insomnia, and other sleep
                  disorders
                </td>
              </tr>
              <tr>
                <td>Interventional Pulmonology</td>
                <td>e.g., Bronchoscopy, pleural procedures, lung biopsy</td>
              </tr>
              <tr>
                <td>Pulmonary Hypertension</td>
                <td>
                  e.g., Specialized treatment of high blood pressure in the
                  lungs
                </td>
              </tr>
              <tr>
                <td>Cystic Fibrosis</td>
                <td>
                  e.g., Management of CF patients, advanced lung disease care
                </td>
              </tr>
              <tr>
                <td>Lung Transplantation</td>
                <td>
                  e.g., Pre- and post-transplant care for lung transplant
                  patients
                </td>
              </tr>
              <tr>
                <td>Asthma and COPD Management</td>
                <td>e.g., Advanced care for chronic respiratory diseases</td>
              </tr>
              <tr>
                <td rowspan='6'>Rheumatology</td>
                <td>Autoimmune Disease Management</td>
                <td>e.g., Lupus, rheumatoid arthritis, vasculitis</td>
              </tr>
              <tr>
                <td>Musculoskeletal Ultrasound</td>
                <td>
                  e.g., Diagnostic and therapeutic use of ultrasound in joint
                  diseases
                </td>
              </tr>
              <tr>
                <td>Connective Tissue Diseases</td>
                <td>e.g., Scleroderma, Sjögren's syndrome</td>
              </tr>
              <tr>
                <td>Pediatric Rheumatology</td>
                <td>e.g., Juvenile idiopathic arthritis, pediatric lupus</td>
              </tr>
              <tr>
                <td>Bone Health</td>
                <td>
                  e.g., Management of osteoporosis, metabolic bone disease
                </td>
              </tr>
              <tr>
                <td>Vasculitis</td>
                <td>
                  e.g., Specialized treatment of inflammation of blood vessels
                </td>
              </tr>
              <tr>
                <td rowspan='7'>Oncology and Hematology</td>
                <td>Medical Oncology</td>
                <td>
                  e.g., Chemotherapy, targeted therapy, and immunotherapy for
                  cancer
                </td>
              </tr>
              <tr>
                <td>Hematologic Malignancies</td>
                <td>e.g., Leukemia, lymphoma, myeloma management</td>
              </tr>
              <tr>
                <td>Bone Marrow Transplantation</td>
                <td>e.g., Stem cell transplantation for blood cancers</td>
              </tr>
              <tr>
                <td>Palliative Oncology</td>
                <td>
                  e.g., Symptom management, end-of-life care in cancer patients
                </td>
              </tr>
              <tr>
                <td>Genetic Cancer Syndromes</td>
                <td>
                  e.g., Management of hereditary cancer risks, genetic
                  counseling
                </td>
              </tr>
              <tr>
                <td>Benign Hematology</td>
                <td>
                  e.g., Treatment of anemia, clotting disorders, platelet
                  disorders
                </td>
              </tr>
              <tr>
                <td>Geriatric Oncology</td>
                <td>
                  e.g., Cancer treatment in elderly patients, age-specific care
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalSPQ;
