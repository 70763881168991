import { apiSlice } from './apiSlice';
import { WORKLIST_URL } from '../constants';

export const worklistApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createWorklist: builder.mutation({
            query: () => ({
                url: `${WORKLIST_URL}`,
                method: 'POST',

            }),
        }),
        getWorklistDetails: builder.query({
            query: (id) => ({
                url: `${WORKLIST_URL}/${id}`,   
            }),
            keepUnusedDataFor: 5,
        }),

        getWorklistDayTotals: builder.query({
            query: (id) => ({
                url: `${WORKLIST_URL}/manager/day/${id}`,
            }),
            keepUnusedDataFor: 5,
        }),

        getWorklistCSTotals: builder.query({
            query: (id) => ({
                url: `${WORKLIST_URL}/manager/clinicalSite/${id}`,
            }),
            keepUnusedDataFor: 5,
        }),

        createActivity: builder.mutation({
            query: (worklistId) => ({


                url: `${WORKLIST_URL}/worklist/${worklistId}/activity`,
                method: 'PUT',

            }),
        }),


        approveAllActivity: builder.mutation({
            query: (worklistId) => ({
                
                url: `${WORKLIST_URL}/${worklistId}/approve-items`,
                method: 'PUT',

            }),
        }),



       

        

        getMyWorklists: builder.query({
            query: () => ({
                url: `${WORKLIST_URL}/mine`,
            }),
            keepUnusedDataFor: 5,
        }),
        getWorklists: builder.query({
            query: () => ({
                url: WORKLIST_URL,
            }),
            keepUnusedDataFor: 5,
        }),

        getAggregateWorkData: builder.query({
            query: (userId) => ({
                url: `${WORKLIST_URL}/aggregateWorkData/${userId}`,
            }),
            keepUnusedDataFor: 5,
        }),

        getAggregateWorkDataActivity: builder.query({
            query: (userId) => ({
                url: `${WORKLIST_URL}/aggregateWorkDataAcitvity/${userId}`,
            }),
            keepUnusedDataFor: 5,
        }),

        manApproveWorklist: builder.mutation({
            query: ({ worklistId, details }) => ({
                url: `${WORKLIST_URL}/${worklistId}/pay`,
                method: 'PUT',
                body: details,
            }),
        }),

        adminApproveWorklist: builder.mutation({
            query: (worklistId) => ({
                url: `${WORKLIST_URL}/${worklistId}/deliver`,
                method: 'PUT',
            }),

        }),


        updateWorklistActivity: builder.mutation({
            query: ({ worklistId, details, UKey }) => ({
                url: `${WORKLIST_URL}/worklist/${worklistId}/activity/${UKey}`,
                method: 'PUT',
                body: details,
            }),

        }),

        deleteWorklistActivity: builder.mutation({
            query: ({ worklistId, uKey }) => ({
                url: `${WORKLIST_URL}/delete/worklist/${worklistId}/activity/${uKey}`,
                method: 'PUT',
                //body: details,
            }),

        }),

        updateWorklist: builder.mutation({
            query: ({ worklistId, details }) => ({
                url: `${WORKLIST_URL}/worklist/${worklistId}`,
                method: 'PUT',
                body: details,
            }),

        }),

        getWorklistItems: builder.query({
            query: ({ worklistID, activityID }) => (
                {

                    url: `${WORKLIST_URL}/worklist/${worklistID}/activity/${activityID}`,
                }),

            keepUnusedDataFor: 5,
        }),


        usersWithUnapprovedItemsManager:  builder.query({
            query: (clinicalDirectorId) => ({
                url: `${WORKLIST_URL}/manager/${clinicalDirectorId}/worklistItems`,
            }),
            keepUnusedDataFor: 5,
        }),
        
        UsersWithApprovedItemsManager:  builder.query({
            query: (clinicalDirectorId) => ({
                url: `${WORKLIST_URL}/manager/approved/${clinicalDirectorId}/worklistItems`,
            }),
            keepUnusedDataFor: 5,
        }),





    }),
});

export const {
    useCreateWorklistMutation,
    useCreateActivityMutation,
    useApproveAllActivityMutation,
    useGetWorklistDetailsQuery,
    useGetMyWorklistsQuery,
    useGetWorklistsQuery,
    useManApproveWorklistMutation,
    useAdminApproveWorklistMutation,
    useUpdateWorklistActivityMutation,
    useUpdateWorklistMutation,
    useGetWorklistItemsQuery,
    useUsersWithUnapprovedItemsManagerQuery,
    useUsersWithApprovedItemsManagerQuery,
    useGetWorklistDayTotalsQuery,
    useGetWorklistCSTotalsQuery,
    useDeleteWorklistActivityMutation,
    useGetAggregateWorkDataQuery,
    useGetAggregateWorkDataActivityQuery,

} = worklistApiSlice;
