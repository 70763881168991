import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import Paginate from '../../components/PaginateLocations';
import {
    useGetLocationsQuery,
    useDeleteLocationMutation,
    useCreateLocationMutation,
} from '../../slices/adminLocationApiSlice';

import { toast } from 'react-toastify';

const LocationListScreen = () => {
    const { pageNumber } = useParams();

    const { data, isLoading, error, refetch } = useGetLocationsQuery({
        pageNumber,
    });

    const [deleteLocation, { isLoading: loadingDelete }] =
        useDeleteLocationMutation();

    const deleteHandler = async (id) => {
        if (window.confirm(`Are you sure  ${id}`)) {
            try {
                await deleteLocation(id);
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    const [createLocation, { isLoading: loadingCreate }] =
        useCreateLocationMutation();

    const createLocationHandler = async () => {
        if (window.confirm('Are you sure you want to create a new Location?')) {
            try {
                await createLocation();
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    return (
        <>
            <Row className='align-items-center'>
                <Col>
                    <h1>Clinical Sites</h1>
                </Col>
                <Col className='text-end'>
                    <Button className='my-3' onClick={createLocationHandler}>
                        <FaPlus /> Create A New Location with in a Directorate
                    </Button>
                </Col>
            </Row>

            {loadingCreate && <Loader />}
            {loadingDelete && <Loader />}
            {isLoading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error.data.message}</Message>
            ) : (
                <>
                    <Table striped bordered hover responsive className='table-sm'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Clinical Site Name</th>
                                <th> Description - Comments</th>
                                <th>Active Y or N</th>

                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.locations.map((location) => (
                                <tr key={location._id}>
                                    <td>{location._id}</td>
                                    <td>{location.locationName}</td>
                                    <td>{location.description}</td>
                                    <td>{location.activeYorN}</td>


                                    <td>
                                        <LinkContainer to={`/admin/location/${location._id}/edit`}>
                                            <Button variant='outline-info' className='btn-sm mx-2'>
                                                <FaEdit /> Edit Item
                                            </Button>
                                        </LinkContainer>

                                        <Button
                                            variant='warning'
                                            className='btn-sm'
                                            onClick={() => deleteHandler(location._id)}
                                        >
                                            <FaTrash className='text-end' style={{ color: 'white' }} /> Delete
                                        </Button>

                                    </td>

                                    {/*
                  

                                    <td>
                                        <LinkContainer to={`/admin/location/${location._id}/edit`}>
                                            <Button variant='light' className='btn-sm mx-2'>
                                                <FaEdit />
                                            </Button>
                                        </LinkContainer>
                                        <Button
                                            variant='danger'
                                            className='btn-sm'
                                            onClick={() => deleteHandler(location._id)}
                                        >
                                            <FaTrash style={{ color: 'white' }} />
                                        </Button>
                                    </td>

                            */}

                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Paginate pages={data.pages} page={data.page} isAdmin={true} />
                </>
            )}
        </>
    );
};

export default LocationListScreen;