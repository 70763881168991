function addDecimals(num) {
    return (Math.round(num * 100) / 100).toFixed(2);
}

export function calcTotal(worklistItems) {

   //console.log('worklistItems', worklistItems.hours);

    const itemsTotalMinutes = worklistItems.reduce((total, item) => {
        const { period, activity, startHour, startMin, endHour, endMin,isActive} = item;
        
        // Check if the item should be included
        if (isActive && !period.startsWith("For Noting") && !activity.startsWith("Lunch")) {
            const startTimeInMinutes = startHour * 60 + startMin;
            const endTimeInMinutes = endHour * 60 + endMin;
            return total + (endTimeInMinutes - startTimeInMinutes);
        }
        
        // If the item should be excluded, just return the total so far
        return total;
    }, 0);

    const hours = Math.floor(itemsTotalMinutes / 60);
    const minutes = itemsTotalMinutes % 60;

    //const itemsTotal = addDecimals(itemsTotalMinutes / 60); // If you need the total in hours with decimals
    const itemsTotal = `${hours}:${minutes}`
    // const totalTime = (Number(itemsTotal) ).toFixed(2);
    return itemsTotal; //{ itemsTotal, hours, minutes };
}