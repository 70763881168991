import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import {
  useGetMyWorklistsQuery,
  useGetWorklistsQuery,
  useGetWorklistDetailsQuery,
  useUpdateWorklistMutation,
} from '../slices/worklistsApiSlice';

const WorklistEditScreen = () => {
  const { pageNumber } = useParams();

  const { id: worklistId } = useParams();
  const { userInfo } = useSelector((state) => state.auth);

  const [worklistName, setWorklistName] = useState('');
  const [description, setDescription] = useState('');

  const {
    data: worklist,
    refetch,
    isLoading,
    error,
  } = useGetWorklistDetailsQuery(worklistId);

  //#f5f5dc
  const dropdownStyle = {
    backgroundColor: '#F5F5F5', //#F0E68C'   // '#f0f8ff'                 //'lightyellow',
    // Add other styles as needed
  };
  const textStyle = {
    backgroundColor: '#f5f5dc',
    // Add other styles as needed
  };

  const [updateWorklist, { isLoading: loadingUpdate }] =
    useUpdateWorklistMutation();

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    //console.log("worklistId", worklistId)
    //console.log("worklistName", worklistName)

    const details = {
      worklistId,
      worklistName,
      description,
    };
    e.preventDefault();
    try {
      await updateWorklist({
        worklistId,
        details,
      }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      toast.success('WorkList updated');
      refetch();
      navigate('/workList');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  /*
    useEffect(() => {
        // Set an initial date when the component mounts
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        setReviewDate(formattedDate);
        

    }, []); // The empty dependency array ensures this effect runs only once when the component mounts

*/

  useEffect(() => {
    if (worklist) {
      setWorklistName(worklist.worklistName);
      setDescription(worklist.description);
    }
  }, [worklist]);

  return (
    <>
      <Link to='/workList' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Worklist</h1>
        {loadingUpdate && <Loader />}

        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error.data.message}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <div
              style={{
                border: '1px solid #ddd',
                padding: '10px',
                borderRadius: '10px',
                backgroundColor: '#F0F8FF',
              }}
            >
              <Form.Group controlId='worklistname'>
                <Form.Label>Work List Name</Form.Label>

                <Form.Control
                  type='text'
                  placeholder='Worklist Name'
                  value={worklistName}
                  onChange={(e) => setWorklistName(e.target.value)}
                  style={textStyle}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='description'>
                <Form.Label>Work List description</Form.Label>

                <Form.Control
                  type='text'
                  placeholder='Worklist description'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  style={textStyle}
                ></Form.Control>
              </Form.Group>
            </div>

            <Button
              type='submit'
              variant='primary'
              style={{ marginTop: '1rem' }}
            >
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default WorklistEditScreen;
