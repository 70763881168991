import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import { FaCalculator } from 'react-icons/fa';

import { Table, Form, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { FaEdit, FaPlus, FaTrash, FaTimes } from 'react-icons/fa';

import { toast } from 'react-toastify';
import Message from '../components/Message';
import Loader from '../components/Loader';

import {
  useGetWorklistDayTotalsQuery,
  useGetWorklistCSTotalsQuery,
} from '../slices/worklistsApiSlice';

//import { setCredentials } from '../slices/authSlice';
import { Link } from 'react-router-dom';
import MyClinicalSCommitments from '../components/MyClinicalCommitments';
import MyProfile from '../components/MyProfile';
import { calcTotal } from '../utils/calcTotals.js';
import '../utils/tableStack.css';

//#f5f5dc
const dropdownStyle = {
  backgroundColor: '#F5F5F5', //'#F0E68C'                 //'lightyellow',
  // Add other styles as needed
};
const textStyle = {
  backgroundColor: '#f5f5dc',
  // Add other styles as needed
};

function ModalScreen({ worklistId, worklistItems }) {
  //console.log('worklistId ', worklistId);

  const [show, setShow] = useState(false);

  const {
    data: worklistsDayTotal,
    isLoading,
    error,
    refetch,
  } = useGetWorklistDayTotalsQuery(worklistId);

  const {
    data: worklistsCSTotal,
    isLoading: isLoadingCS,
    error: errorCS,
    refetch: refetchCS,
  } = useGetWorklistCSTotalsQuery(worklistId);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //console.log('worklistsDayTotal ', worklistsDayTotal);
  //console.log('worklistsCSTotal  ', worklistsCSTotal);

  useEffect(() => {
    // Do something when worklistItems change
  }, [worklistItems]); // Dependency array includes worklistItems

  return (
    <>
      {isLoadingCS && <Loader />}
      {isLoading && isLoadingCS ? (
        <>
          <Loader />
          <Loader /> {/* Second loader */}
        </>
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Button variant='outline-info' size='sm' onClick={handleShow}>
            <FaCalculator /> Calculations
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Calculations</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Hours by Clinical Site
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Clinical Site</th>
                    <th>Total Time</th>
                  </tr>
                </thead>
                <tbody>
                  {worklistsCSTotal &&
                    worklistsCSTotal.map((item, index) => (
                      <tr key={index}>
                        <td>{item.clinicalSite}</td>
                        <td>{item.totalTime}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              Hours By Day
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Day</th>
                    <th>Total Time</th>
                  </tr>
                </thead>
                <tbody>
                  {worklistsDayTotal &&
                    worklistsDayTotal
                      .slice() // Create a shallow copy of the array to avoid mutating the original
                      .sort((a, b) => {
                        // Compare the day fields
                        if (a.day < b.day) return -1;
                        if (a.day > b.day) return 1;
                        return 0;
                      })
                      .map((item, index) => (
                        <tr key={index}>
                          <td>{item.day.split('.')[1]}</td>
                          <td>{item.totalTime}</td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                Close
              </Button>
              {/*
              <Button variant='primary' onClick={handleClose}>
                Save Changes
              </Button>
                */}
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default ModalScreen;
