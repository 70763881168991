import { Chart as ChartJS } from 'chart.js/auto';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import React, { useRef, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';

import '../utils/tableStack.css';

// Register the plugins to all charts:
ChartJS.register(TreemapController, TreemapElement);

function TreemapChart2() {
  const location = useLocation();
  const { data, worklistId, theheading } = location.state || {};
  const chartRef = useRef(null);
  const [isChartReady, setIsChartReady] = useState(false);

  console.log('TreemapChart2 : data', data);
  const { userInfo } = useSelector((state) => state.auth);
  console.log('TreemapChart2  userInfo', userInfo);

  const myUserId = userInfo._id;

  console.log('TreeMap WorkVolumeChart : userId', myUserId);

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = new ChartJS(chartRef.current, {
        type: 'treemap',
        data: {
          datasets: [
            {
              label: `Weekly Activity Time Chart for - ${userInfo.name}  ${theheading}`,
              tree: data,
              key: 'theWorkTime',
              groups: ['activity'],
              spacing: 0.5,
              borderWidth: 1,
              borderColor: 'rgba(0,0,0,0.1)',
              //backgroundColor: 'rgba(0,123,255,0.5)',
              backgroundColor: (ctx) => {
                console.log('backgroundColor: ctx', ctx);

                if (!ctx || !ctx.raw || !ctx.raw._data || !ctx.raw._data.num) {
                  return 'rgba(0, 123, 255, 0.4)';
                }
                const alpha = (1 + Math.log(ctx.raw._data.num)) / 5;
                return `rgba(0, 128, 0, ${alpha})`;
              },
              hoverBackgroundColor: 'rgba(0,123,255,0.7)',

              labels: {
                display: true,
                formatter: (ctx) => {
                  console.log('ctx inside formatter:', ctx);

                  if (ctx && ctx.raw.g && ctx.raw.v) {
                    return `${ctx.raw.g} - ${ctx.raw.v}`;
                  }
                  return 'blank'; // Return 'blank' if label or num is undefined
                },
                color: 'black',
                font: {
                  size: 12,
                },
                align: 'center',
                anchor: 'center',
                clip: true,
                overflow: 'fit',
              },
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
        },
      });

      // Indicate that the chart is ready
      setIsChartReady(true);

      return () => {
        chartInstance.destroy();
      };
    }
  }, [data]);

  // Function to handle print action
  const handlePrint = () => {
    if (isChartReady && chartRef.current) {
      const canvas = chartRef.current;
      const dataUrl = canvas.toDataURL();
      const printWindow = window.open('', '_blank');
      printWindow.document.write(
        '<html><head><title>Activity Chart</title></head><body>'
      );
      printWindow.document.write(
        `<img src="${dataUrl}" onload="window.print();window.close()" />`
      );
      printWindow.document.write('</body></html>');
      printWindow.document.close();
    }
  };

  return (
    <>
      <Link to={`/worklist/${worklistId}`} className='btn btn-light mb-4'>
        Go Back
      </Link>

      <div>
        <h2>Activity Time Chart </h2>
        <canvas ref={chartRef} />
      </div>

      <Button variant='outline-primary' size='sm' onClick={handlePrint}>
        Print Chart
      </Button>
    </>
  );
}

export default TreemapChart2;
