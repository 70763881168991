import { apiSlice } from './apiSlice';
import { PRIVILEGE_URL } from '../constants';

export const privilegeApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createPrivilege: builder.mutation({
            query: () => ({
                url: `${PRIVILEGE_URL}`,
                method: 'POST',

            }),
        }),

        getPrivilege: builder.query({     
            query: (id) => ({
                url: `${PRIVILEGE_URL}/${id}`,   
            }),
            keepUnusedDataFor: 5,
        }),

        
      

        createItem: builder.mutation({
            query: (privilegeId) => ({


                url: `${PRIVILEGE_URL}/privilege/${privilegeId}/item`,
                method: 'PUT',

            }),
        }),



        getMyPrivileges: builder.query({
            query: () => ({
                url: `${PRIVILEGE_URL}/mine`,
            }),
            keepUnusedDataFor: 5,
        }),
        
        getPrivileges: builder.query({
            query: () => ({
                url: PRIVILEGE_URL,
            }),
            keepUnusedDataFor: 5,
        }),

       
        updatePrivilegeItem: builder.mutation({
            query: ({ specialityId,  privilegeId,  details, }) => ({
                url: `${PRIVILEGE_URL}/privilege/${specialityId}/item/${privilegeId}`,
                method: 'PUT',
                body: details,
            }),

        }),

        deletePrivilegeItem: builder.mutation({
            query: ({ specialityId, privilegeId }) => ({
                url: `${PRIVILEGE_URL}/delete/privilege/${specialityId}/item/${privilegeId}`,
                method: 'PUT',
                //body: details,
            }),

        }),

        updatePrivilege: builder.mutation({
            query: ({ privilegeId, details }) => ({
                url: `${PRIVILEGE_URL}/privilege/${privilegeId}`,
                method: 'PUT',
                body: details,
            }),

        }),

        getPrivilegeItem: builder.query({
            query: ({ specialityID, privilegeID }) => (
                {

                    url: `${PRIVILEGE_URL}/privilege/${specialityID}/item/${privilegeID}`,
                }),

            keepUnusedDataFor: 5,
        }),

         // Fetch all privilege assignments for a specific user
    getUserPrivilegeAssignments: builder.query({
        query: (userId) => ({
          url: `${PRIVILEGE_URL}/assignments/${userId}`,
        }),
        keepUnusedDataFor: 5,
      }),


         // Create a privilege assignment for a user
         createPrivilegeAssignment: builder.mutation({
            query: (assignmentData) => ({
                url: `${PRIVILEGE_URL}/assign`,
                method: 'POST',
                body: assignmentData,
            }),
        }),

        // Update a privilege assignment for a user
        updatePrivilegeAssignment: builder.mutation({
            query: ({ assignmentId, updatedData }) => ({
                url: `${PRIVILEGE_URL}/assign/${assignmentId}`,
                method: 'PUT',
                body: updatedData,
            }),
        }),

        // Delete a privilege assignment for a user, delete changed to put to change isActive to false
        deletePrivilegeAssignment: builder.mutation({
            query: (assignmentId) => ({
                url: `${PRIVILEGE_URL}/assign/delete/${assignmentId}`,
                method: 'PUT',
                //method: 'DELETE',
            }),
        }),


    }),
});

export const {
    useCreatePrivilegeMutation,
    useCreateItemMutation,
    useGetPrivilegeQuery,
    useGetPrivilegeItemQuery,
    useUpdatePrivilegeItemMutation,

    
    useGetMyPrivilegesQuery,
    useGetPrivilegesQuery,
    
    useUpdatePrivilegeMutation,
        
    useDeletePrivilegeItemMutation,

    useCreatePrivilegeAssignmentMutation,
    useUpdatePrivilegeAssignmentMutation,
    useDeletePrivilegeAssignmentMutation,
    useGetUserPrivilegeAssignmentsQuery,

    
    
    


    
} = privilegeApiSlice;
