import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import Message from './Message';
import Loader from './Loader';
import Paginate from './PaginateCommitments';
import { useDispatch, useSelector } from 'react-redux';

import {
  useProfileMutation,
  useGetUserProDetailsQuery,
} from '../slices/usersApiSlice';
//import { setCredentials } from '../slices/authSlice';

import { toast } from 'react-toastify';

const MyProfile = ({ userid }) => {
  //console.log('in MyProfile , the userid is ', userid);

  const { userInfo } = useSelector((state) => state.auth);
  const { pageNumber } = useParams();

  //console.log("in MyProfile , the userid is ", userid)
  //console.log("in MyProfile , the userInfo._Id is ", userInfo._Id)

  let theUser = '';

  if (userid) {
    // If userid has a value
    theUser = userid;
  } else {
    // If userid is null
    theUser = userInfo._Id;
  }

  //console.log("in MyProfile , the theUser is ", theUser)

  const { data, isLoading, error, refetch } =
    useGetUserProDetailsQuery(theUser); //userInfo._Id

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <LinkContainer to='/profile'>
            <div className='btn btn-primary btn-sm'>
              {' '}
              {/* Using Bootstrap button classes */}
              Profile
            </div>
          </LinkContainer>
        </Col>
      </Row>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error.data.message}</Message>
      ) : (
        <>
          <Table
            striped
            bordered
            hover
            responsive
            className='table-sm'
            style={{ fontSize: '0.8rem', padding: '0.1rem' }}
          >
            <thead>
              <tr>
                <th style={{ width: '10%' }}>Speciality</th>
                <th style={{ width: '10%' }}>Name</th>
                <th style={{ width: '10%' }}>Effective Date</th>
                <th style={{ width: '10%' }}>Contract</th>
              </tr>
            </thead>
            <tbody>
              <tr key={data._id}>
                <td style={{ width: '10%' }}>{data.speciality}</td>
                <td style={{ width: '10%' }}>{data.name}</td>

                <td>
                  {isNaN(Date.parse(data.effectiveDate))
                    ? ''
                    : new Date(data.effectiveDate).toLocaleDateString('en-GB')}
                </td>
                <td style={{ width: '10%' }}>{data.contracttype}</td>
              </tr>
            </tbody>
          </Table>
          <Paginate pages={data.pages} page={data.page} isAdmin={true} />
        </>
      )}
    </>
  );
};

export default MyProfile;
