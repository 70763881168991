import { CLINICALDIRECTORS_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const clinicalDirectorsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getClinicalDirectors: builder.query({
            query: ({ keyword, pageNumber }) => ({
                url: CLINICALDIRECTORS_URL,
                params: { keyword, pageNumber },
            }),
            keepUnusedDataFor: 5,
            providesTags: ['ClinicalDirectors'],
        }),

        getClinicalDirectorsNP: builder.query({
            query: () => ({
                url: `${CLINICALDIRECTORS_URL}/list`,

            }),
            keepUnusedDataFor: 5,
            providesTags: ['ClinicalDirectors'],
        }),



        getClinicalDirectorDetails: builder.query({
            query: (clinicalDirectorId) => ({
                url: `${CLINICALDIRECTORS_URL}/${clinicalDirectorId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        createClinicalDirector: builder.mutation({
            query: () => ({
                url: `${CLINICALDIRECTORS_URL}`,
                method: 'POST',
            }),
            invalidatesTags: ['ClinicalDirector'],
        }),
        updateClinicalDirector: builder.mutation({
            query: (data) => ({
                url: `${CLINICALDIRECTORS_URL}/${data.clinicalDirectorId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['ClinicalDirector'],
        }),
        deleteClinicalDirector: builder.mutation({
            query: (clinicalDirectorId) => ({
                url: `${CLINICALDIRECTORS_URL}/${clinicalDirectorId}`,
                method: 'DELETE',
            }),
            providesTags: ['ClinicalDirector'],
        }),


    }),
});

export const {
    useGetClinicalDirectorsQuery,
    useGetClinicalDirectorsNPQuery,
    useGetClinicalDirectorDetailsQuery,
    useCreateClinicalDirectorMutation,
    useUpdateClinicalDirectorMutation,
    useDeleteClinicalDirectorMutation,
} = clinicalDirectorsApiSlice;
