import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit, FaPlus, FaTrash, FaTimes, FaCheck } from 'react-icons/fa';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import TimePicker from '../../components/TimePicker.jsx';

import {
  useGetPrivilegeQuery,
  useUpdatePrivilegeMutation,
  useGetPrivilegeItemQuery,
  useGetWorklistDetailsQuery,
  useUpdatePrivilegeItemMutation,
} from '../../slices/privilegeApiSlice';

//import { setCredentials } from '../slices/authSlice';
import FormContainer from '../../../src/components/FormContainer';

import ModalScreen from '../../components/ModalActivityNote.jsx';

const checkboxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '90px',
  height: '30px',
  border: '2px solid #ccc',
  borderRadius: '4px',
  cursor: 'pointer',
};

const approvedStyle = {
  border: '2px solid green',
};

const notApprovedStyle = {
  border: '2px solid red',
};

const disabledStyle = {
  backgroundColor: '#e9ecef',
  cursor: 'not-allowed',
  opacity: 0.5,
};

const dropdownStyle = {
  backgroundColor: '#F5F5F5',
};

const textStyle = {
  backgroundColor: '#f5f5dc',
};

const SpecialityPrivilegeEditScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const myUserId = userInfo._id;

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const [isDisabled, setIsDisabled] = useState(true);

  // Function to handle modal confirmation
  const handleConfirm = () => {
    setShow(false);
    refetch();
    navigate(`/admin/privilege/${specialityId}`);
  };

  // Function to handle modal close without action
  const handleClose = () => {
    setShow(false);
  };

  //console.log('in WorklistEditItemScreen, myUserId', myUserId);

  const { pageNumber } = useParams();

  const { id: specialityId, privilegeID: privilegeId } = useParams();

  //console.log('id: specialityId', specialityId);
  //console.log('privilegeID: privilegeId', privilegeId);

  const [privilegeName, setPrivilegeName] = useState('');
  const [description, setDescription] = useState('');

  const [isActive, setIsActive] = useState(true);

  const {
    data: theItem,
    refetch,
    isLoading,
    error,
  } = useGetPrivilegeItemQuery({
    specialityID: specialityId,
    privilegeID: privilegeId,
  });

  const dispatch = useDispatch();

  const [updatePrivilegeItem, { isLoading: loadingUpdate }] =
    useUpdatePrivilegeItemMutation();

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    //console.log('frontend activity', activity);

    const details = {
      privilegeName,
      description,
      isActive,
    };
    //console.log('submitHandler, details', details);

    //console.log('submitHandler, specialityId ', specialityId);
    //console.log('submitHandler, privilegeId ', privilegeId);

    try {
      await updatePrivilegeItem({
        specialityId: specialityId,
        privilegeId: privilegeId,
        details,
      }).unwrap();
      toast.success('Privilege Item updated');
      refetch();
      navigate(`/admin/privileges/${specialityId}/list`);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };
  //
  useEffect(() => {
    if (theItem) {
      setPrivilegeName(theItem.privilegeName);
      setDescription(theItem.description);
      setIsActive(theItem.isActive);
    }
  }, [theItem]);

  //-------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Link
        to={`/admin/privileges/${specialityId}`}
        className='btn btn-light my-3'
      >
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Privilege Form...</h1>

        <Col xs='auto'>
          {' '}
          {/* Adjust to avoid column taking full width ###### show Time Overlap below ###  //  */}
        </Col>

        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error.data.message}</Message>
        ) : (
          <div
            style={{
              border: '1px solid #ddd',
              padding: '10px',
              borderRadius: '10px',
              backgroundColor: '#F0F8FF',
            }}
          >
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='privilege'>
                <Form.Label>Privilege Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='privilege name'
                  value={privilegeName}
                  onChange={(e) => setPrivilegeName(e.target.value)}
                  style={textStyle}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='description'>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as='textarea' // This makes it a textarea
                  placeholder='description'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  style={textStyle}
                  rows={4} // Optional: to control the number of rows in the textarea
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='isActive'>
                <Form.Label>Is Active?</Form.Label>
                <div
                  style={{
                    ...checkboxStyle,
                    ...(isActive ? approvedStyle : notApprovedStyle),
                    ...(isDisabled ? disabledStyle : {}),
                  }}
                  onClick={() => !isDisabled && setIsActive(!isActive)}
                >
                  {isActive ? (
                    <FaCheck style={{ color: 'green' }} />
                  ) : (
                    <FaTimes style={{ color: 'red' }} />
                  )}
                </div>
              </Form.Group>

              <Button
                type='submit'
                variant='primary'
                style={{ marginTop: '1rem' }}
              >
                Update
              </Button>
            </Form>
          </div>
        )}
      </FormContainer>
    </>
  );
};

export default SpecialityPrivilegeEditScreen;
